import React, { useContext, useRef, useCallback } from "react";

const fieldChangeWatcherContext = React.createContext();

export function useFieldChangeWatcherContext() {
    return useContext(fieldChangeWatcherContext);
}

export function FieldChangeWatcherProvider({ children }) {
    const contextControlsRef = useRef([]);
    const hasPendingChangesRef = useRef(false);

    const updateHasPendingChanges = useCallback(() => {
        hasPendingChangesRef.current = (contextControlsRef.current.filter(c => c.edited === true).length > 0)
    }, [contextControlsRef.current]);

    return (
        <fieldChangeWatcherContext.Provider value={{ contextControlsRef, updateHasPendingChanges, hasPendingChangesRef }}>
            {children}
        </fieldChangeWatcherContext.Provider>
    );
}