import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';

import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Varieties/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Varieties/VarietyStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { ItemsTable } from '../../Shared/ItemsTable/ItemsTable';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [variety, setVariety] = useState([]);
    const [buttonsVariety, setButtonsVariety] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setVariety({
                id: result.data.id,
                name: result.data.name,
                items: result.data.items,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !variety.active);
        if (result.isSuccessful) {
            setVariety((prevVariety) => {
                return {
                    ...prevVariety,
                    active: !variety.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = variety.active ? "Inactivar" : "Activar";
                let statusiconbutton = variety.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = variety.active ? 118 : 203;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la variedad?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !variety.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }
    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    useEffect(() => {
        if (variety) {
            setButtonsVariety(prevVariety => ({
                ...prevVariety,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...variety, acronym: localStorage.getItem("userName") });
        }
    }, [variety?.active]);

    useEffect(() => {
        if (variety) {
            setButtonsVariety(prevVariety => ({
                ...prevVariety,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);   

    return (
        <>
            {variety.length !== 0 &&
                (<BarButtons rightButtons={buttonsVariety.rightButtons}
                leftButtons={buttonsVariety.leftButtons} />)}
            
            <Pivot className="pivot-center-client">
                        <PivotItem headerText="Información" >
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            <div className='row'>
                                                {
                                                    useForeignKey &&
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniLabel text='Clave SAP' value={id} />
                                                    </div>
                                                }
                                                <div className='col-lg-12 col-md-12'>
                                                    <OmniLabel text='Nombre' value={variety.name} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Artículos" itemKey="items">
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Artículos  '>
                                            <div id='divItemsContainer' className='row card-items-container'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <ItemsTable titleheader='la variedad' items={variety.items} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={variety} />
                </PivotItem>
                    </Pivot>         
        </>
    )
}