import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';

import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Creditor/Add';

import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';

const getCreditorAccountUrl = '/creditoraccount/GetByStatus';
const getCreditorSubAccountUrl = '/creditorsubaccount/GetByIdCreditorAccount';
const addUrl = '/creditor/add';

export default function Add() {
    const { register, handleSubmit } = useForm();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const [listCreditorAccount, setListCreditorAccount] = useState();
    const [creditorAccount, setCreditorAccount] = useState(0);
    const [creditorSubAccount, setCreditorSubAccount] = useState(null);

    const onSubmit = async (data) => {      
        try {
            const response = await axiosPrivate
                .post(addUrl,
                    JSON.stringify({
                        name: data.name,
                        rfc: data.rfc,
                        creditoraccountid: data.creditoraccountid,
                        creditorsubaccountid: data.creditorsubaccountid,
                        loggedUserName: localStorage.getItem("userName")
                    }))
            if (response.data.isSuccessful) {
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage("El acreedor se guardó exitosamente")]);
                changeComponent(<Detail />, response.data.id);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }            
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    };  
    const handleChangeCreditorAccount = (event, data) => {
        setCreditorAccount(data);
    };
    useEffect(() => {        
        const GetCreditorAccount = async () => {
            try {
                let resultList = [];
                let itemCreditorAccount = {};
                const statusFilterParam = { statusFilter: 1, description: '' };
                const response = await axiosPrivate
                    .post(getCreditorAccountUrl, statusFilterParam)               
                response.data.data.map((item) => {
                    itemCreditorAccount = { key: item.id, value: item.id, text: item.description, selected: false }
                    resultList.push(itemCreditorAccount);
                })
                if (response.data.isSuccessful) {
                    setListCreditorAccount(resultList)
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
                } 
            } catch (err) {
                if (!err?.response) {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
                } else if (err.response?.status === 400) {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
                }
            }
        }
        GetCreditorAccount();
    }, []);
    useEffect(() =>    {
        const GetCreditorSubAccount = async () => {
            try {
                let resultList = [];
                let itemCreditorAccount = {};
                const response = await axiosPrivate
                    .get(getCreditorSubAccountUrl,{ params: { idCreditorAccount: creditorAccount } } );
                if (response.data.isSuccessful) {
                    if (response.data.data) {
                        response.data.data.map((item) => {
                            itemCreditorAccount = { key: item.id, value: item.id, text: item.description }
                            resultList.push(itemCreditorAccount);
                        })
                    }
                    setCreditorSubAccount(resultList)
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
                } 
            } catch (err) {
                if (!err?.response) {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
                } else if (err.response?.status === 400) {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
                }
            }
        }
        creditorAccount == 0 ? setCreditorSubAccount(null) : GetCreditorSubAccount();
    }, [creditorAccount]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <OmniCard title='Datos generales'>
                <OmniTextBox required title='Razón social' register={{...register('name') }} focus />
                <OmniTextBox required width='50%' title='RFC' register={{ ...register('rfc') }} />
                <OmniSelect required title='Rubro' register={{ ...register('creditoraccountid') }} onChange={handleChangeCreditorAccount} items={listCreditorAccount} />
                <OmniSelect required title='Subrubro' register={{ ...register('creditorsubaccountid') }} items={creditorSubAccount} />
            </OmniCard>
        </form>
    );
}