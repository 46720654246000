
export const leftButtons = ( handleClickDelete) => [{
    key: 'BtnStatus',
    text: 'Eliminar huella',
    iconName: 'trashCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--danger)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    handler: handleClickDelete
}];