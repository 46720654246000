
import { Hands } from '../../../Assets/Constants/Enums/Hands';
import { Fingers } from '../../../Assets/Constants/Enums/Fingers';
import { Readers } from '../../../Assets/Constants/Enums/Readers';

const getByIdUrl = '/user/GetById';
const getToAutocompleteUrl = '/user/GetToAutocomplete';
const updateStatusUrl = '/user/SetStatus';
const updateUrl = '/user/update';
const addUrl = '/user/add';
const getByWithGrantingUserUrl = '/role/GetByWithGrantingUser';
const existUserNameAsyncUrl = '/user/ValidateUserName';
const addFingerPrintUrl = '/userFingerprint/Add';
const deleteUrl = '/userFingerprint/Delete';

var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getByIdUrl, {
            params: { id: id }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const GetToAutocomplete = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getToAutocompleteUrl,
          null);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        console.log(err);
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusUrl,
            JSON.stringify({
                id: id,
                status: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El empleado ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateUrl,
            JSON.stringify({
                id: id,
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El empleado se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El empleado se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const GetRole = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getByWithGrantingUserUrl, {
            params: { userId: localStorage.getItem("userId") }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}
export function generateObjectFingerPrint(item) {
    return item.map((item) => ({
        id: item.id,
        hand: item?.handId ? Hands.find(x => x.id == item?.handId)?.description :"",
        finger: item?.fingerId ? Fingers.find(x => x.id == item?.fingerId)?.description : "",
        fingerprintType: item?.fingerprintTypeId ? Readers.find(x => x.id == item?.fingerprintTypeId)?.description : "",
    }));
}
export function generateObject(item) {
    return item.map(x => ({ ...x }));
}
export function Age(birthdate) {
    if (birthdate) {
        birthdate = new Date(birthdate);
        let date = new Date();
        let age = date.getFullYear() - birthdate.getFullYear();
        if (
            date.getMonth() < birthdate.getMonth() ||
            (date.getMonth() === birthdate.getMonth() &&
                date.getDate() < birthdate.getDate())
        ) {
            age--;
        }

        return age + " años";
    }
    return "";
}
export function DischargeDate(dischargeDate) {
    if (dischargeDate) {
        dischargeDate = new Date(dischargeDate);
        var fechaActual = new Date();

        var year = fechaActual.getFullYear() - dischargeDate.getFullYear();
        var month = fechaActual.getMonth() - dischargeDate.getMonth();

        if (month < 0) {
            year--;
            month = 12 + month;
        }
        let yearlabel = year === 1 ? " año" : " años";
        let monthlabel = month === 1 ? " mes" : " meses"
        return ` ${year} ${yearlabel} ${month} ${monthlabel}`;
    }
    return "";
}
export const GetHands = () => {
    const hands = Hands.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.description
    }));
    return hands
}
export const GetFingers = () => {
    const fingers = Fingers.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.description
    }));
    return fingers
}
export const GetReaders = () => {
    const readers = Readers.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.description
    }));
    return readers
}
export const ExistUserNameAsync = async (userName) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(existUserNameAsyncUrl,
            JSON.stringify({
                userName: userName
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const AddFingerPrintAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addFingerPrintUrl,
            JSON.stringify({
                ...data,
                loggedUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La huella del empleado se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const DeleteAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(deleteUrl,
            JSON.stringify({
                id:id,
                loggedUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La huella del empleado se eliminó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};