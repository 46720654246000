import { Weekdays } from '../../../Models/Shared/DayOfWeek/Weekdays';
import { Periodicity } from '../../../Assets/Constants/Enums/Periodicity';
import { ReactComponent as CalendarCuvitek } from './../../../Assets/Images/Icons/Calendar.svg';
const getByIdUrl = '/routes/getbyid';
const updateStatusUrl = '/routes/SetStatus';
const updateUrl = '/routes/update';
const addUrl = '/routes/add';
const getClient = '/clients/GetByStatus';
const getShippingAddressByClient = '/shippingAddresses/GetByClientId';
const getUserUrl = '/user/GetByWithRoute';
const addRoutesShippingAddressesUrl = '/routesShippingAddresses/add';
const updateRoutesShippingAddressesUrl = '/routesShippingAddresses/update';
const updateStatusRoutesShippingAddressesUrl = '/routesShippingAddresses/SetStatus';
const getRoutesByClientUrl = '/clients/GetRoutesById';
const getPhotoShippingAddressesUrl = '/shippingAddresses/GetPhotoById';
var axiosPrivate;
export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getByIdUrl, {
            params: { id: id }
        });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusUrl,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activada" : "desactivada";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La ruta de venta ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateRoutesShippingAddressesStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateStatusRoutesShippingAddressesUrl,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        let statustext = status ? "activado" : "desactivado";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `El cliente en la ruta ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateUrl,
            JSON.stringify({
                id: id,
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La ruta de venta se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateRoutesShippingAddressesAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(updateRoutesShippingAddressesUrl,
            JSON.stringify({
                id: id,
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El cliente en la ruta se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La ruta de venta se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const AddRoutesShippingAddressesAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.post(addRoutesShippingAddressesUrl,
            JSON.stringify({
                ...data,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName")
            }));
        result.data = response.data.id;
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `El cliente en la ruta se guardó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const GetClient = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(getClient,
            JSON.stringify({ "StatusFilter": 1, "Description": '' }));
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const GetUser = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getUserUrl);
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const GetShippingAddressByClient = async (clientId) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getShippingAddressByClient,
            {
                params: { clientId: clientId }
            });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}
export const DayOfWeek = () => {
    const days = Weekdays.map((item) => ({
        key: item.number,
        value: item.number === 0 ? 7 : item.number,
        text: item.name,
    }));
    return days
}
export const GetDescriptionDayOfWeek = (id) => {
    const day = Weekdays.filter(item => item.number == (id ?? 1))[0].name.toLowerCase();
    return day;
}
export const GetPeriodicity = () => {
    const periodicity = Periodicity.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.description,
        icon: <CalendarCuvitek/>
    }));
    return periodicity
}
export const GetPeriodicityById = (id) => {
    const description = Periodicity.filter(item => item.id == id)[0].description ?? '';
    return description;
}
export function generateObject(item) {
    if (item) {
        return item.map(x => ({ ...x }));
    }
}
export const GetRoutesByClient = async (clientId) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getRoutesByClientUrl,
            {
                params: { clientId: clientId }
            });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}
export const GetPhotoByIdLocation = async (imageId, imageExtension) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(getPhotoShippingAddressesUrl,
            {
                params: { imageId: imageId, imageExtension: imageExtension },
                responseType: 'arraybuffer' 
            });
        if (response.status === 200) {
            result.data = response.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        if (!err?.response) {
            result.errorMessage = "No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage = err.response?.data?.errorMessage
        }
    }
    return result;
}