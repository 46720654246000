import { Pivot, PivotItem } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Brand/Detail';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, GetDefaultValuesAsync, GetItemsByBrandId } from '../../../Functions/Catalogs/Brand/BrandStore';
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { useIdSelectorContext, useGeneralStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { ItemsTable } from '../../Shared/ItemsTable/ItemsTable';
import { Button } from '@fluentui/react-components';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton, useForeignKey } = useGeneralStatesContext();
    const [brand, setBrand] = useState([]);
    const [buttonsBrand, setButtonsBrand] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const [defaultValue, setDefaultValue] = useState();
    const [brandItems, setBrandItems]=useState();
    const [actualPage, setActualPage]=useState(1);
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setBrand({
                id: result.data.id,
                name: result.data.name,
                items: result.data.items,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
            setBrandItems(result.data.items)
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !brand.active);
        if (result.isSuccessful) {
            setBrand((prev) => {
                return {
                    ...prev,
                    active: !brand.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = brand.active ? "Inactivar" : "Activar";
                let statusiconbutton = brand.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = brand.active ? 539 : 540;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la marca?`, disabled: defaultValue == id,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !brand.active || defaultValue==id,
                };
            }
            return button;
        });
        return updatedButtons;
    }

    const onScrollFunction = event => {
        var top=event.currentTarget.scrollTop;
        var max=350 * 11.5 * parseInt( actualPage);
        
        if(parseFloat(top)>=parseFloat(max)){
            console.log("max: "+max);
            console.log("top: "+top);
            getNewItems();
        }
    }

    const getNewItems = async()=>{
        var page=actualPage;
        page=page+1;
        var items=await GetItemsByBrandId(id, page);
        setActualPage(page++);
        var actualItems=brandItems;
        items.data.map((item)=>{
            brandItems.push(item);
        });
        setBrandItems(actualItems);
    }

    useEffect(() => {
        OnGetDefaultValues();
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);

    useEffect(() => {
        if (brand) {
            setButtonsBrand(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...brand, acronym: localStorage.getItem("userName") })
        }
    }, [brand?.active]);

    useEffect(() => {
        if (brand) {
            setButtonsBrand(prev => ({
                ...prev,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);
    const OnGetDefaultValues = async () => {
        let result = await GetDefaultValuesAsync();
        if (result.isSuccessful) {
            setDefaultValue(result?.data);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    return (
        <>
            {brand.length !==0 && (<BarButtons rightButtons={buttonsBrand.rightButtons}
                leftButtons={buttonsBrand.leftButtons} />)}
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        {
                                            useForeignKey &&
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniLabel text='Clave SAP' value={id} />
                                            </div>
                                        }
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <OmniLabel text='Descripción' value={brand.name} />
                                        {/*<Button className='btn btn-primary'  onClick={getNewItems} >Click </Button>*/} 
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Artículos" itemKey="items">
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Artículos'>
                                    <div id='divItemsContainer' className='row card-items-container' onScroll={onScrollFunction}>
                                        <div className='col-lg-12 col-md-12'>
                                            <ItemsTable titleheader='la marca' items={brandItems}/>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                        <DetailChanges user={brand} />
                </PivotItem>
            </Pivot>
        </>
    )
}
