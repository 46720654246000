import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { rightButtons, leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Routes/ClientRoute/Add";
import { leftButtons as leftButtonsEdit } from "../../../../Assets/Constants/BarButtons/Catalog/Routes/ClientRoute/Edit";
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { useEffect, useState, useCallback, useRef } from 'react';
import { OmniCombobox } from '../../../Shared/Controls/ComboBox/OmniCombobox';
import { OmniDropdown } from '../../../Shared/Controls/Dropdown/OmniDropdown';
import { OmniDatePicker } from '../../../Shared/Controls/DatePicker/OmniDatePicker';
import {
    GetPeriodicity, GetShippingAddressByClient, AddRoutesShippingAddressesAsync,
    GetPeriodicityById, UpdateRoutesShippingAddressesAsync, GetClient
} from '../../../../Functions/Catalogs/Routes/RoutesStore'
import { useIdSelectorContext } from '../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { OtherRoutes } from './OtherRoutes';
import { OmniTimePicker } from '../../../Shared/Controls/TimePicker/OmniTimePicker';
export const ClientRouteAdd = (props) => {
    const id = useIdSelectorContext();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const initialList = {
        periodicityId: GetPeriodicity()[0].value,
        periodicity: GetPeriodicity()[0].text,
        visitingHours: '09:00 AM',
        deliveryTime: '09:00 AM'
    };
    const itemSelected = (props.view[props.property].viewEdit ? props.description[props.property].find(x => x.id == [props?.itemSelected[props.property]]) : undefined);
    const [clientRoute, setClientRoute] = useState(itemSelected ?? initialList);
    const [client, setClien] = useState();

    const [clientLoad, setClientLoad] = useState();
    const [shippingAddress, setShippingAddress] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [serchClient, setSerchClientr] = useState('');
    const initialPickerDate = useRef(null);
    const [showErrors, setShowErrors] = useState({
        shippingAddress: undefined,
        deliveryTime: undefined,
        visitingHours: undefined
    });
    const RangeHours = () => {
        const resultList = [];
        const startTime = 6 * 60;
        const endTime = 21 * 60;
        const interval = 15;

        for (let i = startTime; i <= endTime; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const amPm = hours >= 12 ? 'PM' : 'AM';
            const displayHours = hours > 12 ? hours - 12 : (hours === 0 ? 12 : hours);

            const time = `${displayHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPm}`;

            resultList.push({
                key: i,
                value: i,
                text: time
            });
        }
        return resultList;
    }
    const OnLoadDescripcion = async (url, set, id) => {
        let result = await url(id);
        if (result.isSuccessful) {
            if (result.data) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    shippingAddress: undefined
                }))
                const resultList = props?.description[props?.property] && props?.description[props?.property].length > 0
                    ? result.data.filter((item) => !props?.description[props?.property].some((route) => route.shippingAddressId == item.id))
                        .map((item) => ({
                            key: item.id,
                            value: item.id,
                            text: item.branchName
                        }))
                    : result.data.map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.branchName
                    }));
                if (itemSelected !== undefined) {
                    if (result.data.some((route) => route.id == itemSelected.shippingAddressId)) {

                        resultList.push({
                            key: itemSelected.shippingAddressId,
                            value: itemSelected.shippingAddressId,
                            text: itemSelected.shippingAddress
                        });
                    }
                }
                if (resultList.length === 0) {
                    set(undefined);
                    setShowErrors(prevErrors => ({
                        ...prevErrors,
                        shippingAddress: 'El cliente no cuenta con direcciones de envío'
                    }))
                }
                else {
                    set(resultList);
                }
            }
            else {
                set(undefined);
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    shippingAddress: 'El cliente no cuenta con direcciones de envío'
                }))
            }
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));

        if (clientRoute.clientId !== undefined) {
            OnLoadDescripcion(GetShippingAddressByClient, setShippingAddress, clientRoute.clientId);
        }
    }, [])
    function Return() {
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: false,
                viewDetail: true,
                viewEdit: false
            }
        }));
    }

    function convertTo24Hour(time) {
        const [timeStr, modifier] = time.split(' ');
        let [hours, minutes] = timeStr.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}:00`;
    }

    const onChangeItemClient = async (id, client) => {
        setClientRoute(prevEedit => ({
            ...prevEedit,
            clientId: id,
            client: client,
            shippingAddressId: undefined,
            shippingAddress: undefined
        }));
        OnLoadDescripcion(GetShippingAddressByClient, setShippingAddress, id);

    }
    const OnChangeClientText = async (text) => {
        setShowSpinner(true);
        //let result = await GetClient(text);
        //if (result.isSuccessful) {
        //    if (result.data.length > 0) {
        //        const resultList = result.data.map((item) => ({
        //            key: item.id,
        //            value: item.id,
        //            text: item.businessName
        //        })).slice(0, 100);;
        //        setClientLoad(resultList);
        //    }
        //}
        //else {
        //    setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        //    setShowSpinner(false);
        //}
        if (clientLoad) {
            LoadClient(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: itemSelected !== undefined ? leftButtonsEdit : leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [clientRoute])
    //useEffect(() => {
    //    if (clientLoad) {
    //        setClien(clientLoad);
    //        setShowSpinner(false);
    //    }
    //}, [clientLoad])
    useEffect(() => {
        if (clientLoad) {
            LoadClient(serchClient)
        }
        if (clientRoute?.clientId) {
            OnChangeClientText(clientRoute?.client);
        }
    }, [clientLoad]);
    const OnLoad = async (url, set) => {
        let result = await url();
        if (result.isSuccessful) {
            set(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoad(GetClient, setClientLoad);
    }, [])
    function LoadClient(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = clientLoad.data.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.businessName.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.businessName
                })).slice(0, 100);;
                setClien(resultList);
            }
        }
    }
    function GetMaxDate() {
        const today = new Date();

        let month = today.getMonth() + 1;
        let year = today.getFullYear();
        if (month === 12) {
            month = 0;
            year++;
        }
        const lastDayOfMonth = new Date(year, month+1, 0).getDate();
        const maxDate = new Date(year, month, lastDayOfMonth);
        return maxDate;
    }
    const DisableDays = useCallback(
        (date) => {
            const dayOfWeek = date.getDay();
            let day = props.day === 7 ? 0 : props.day;
            return dayOfWeek !== day;
        },
        [props.day]
    );
    const getRestrictedDates = useCallback(() => {
        const currentDate = new Date();
        const restrictedDates = [];
        for (let i = 0; i < 63; i++) {
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + i);

            if (DisableDays(nextDate)) {
                restrictedDates.push(nextDate);
            }
            else {
                if (!initialPickerDate.current) {
                    initialPickerDate.current = nextDate;
                    
                }
            }
        }
        return restrictedDates;
    }, [DisableDays]);
    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!clientRoute.clientId) {
            errorMessages.push("El cliente es obligatorio");
        }
        if (!clientRoute.shippingAddressId) {
            errorMessages.push("La dirección de envío es obligatoria");
        }
        if (errorMessages.length > 0) {

            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            const updatedClientRoute = {
                ...clientRoute,
                active: true
            };
            updatedClientRoute.periodicityDate = updatedClientRoute.periodicityDate ?? initialPickerDate.current;
            let data = { ...updatedClientRoute };
            data.routeId = id;
            data.day = props.day;
            data.periodicity = updatedClientRoute.periodicityId;
            data.receptionDate = updatedClientRoute.periodicityDate;
            data.visitingHours = convertTo24Hour(updatedClientRoute.visitingHours);
            data.deliveryTime = convertTo24Hour(updatedClientRoute.deliveryTime);
            updatedClientRoute.periodicityDateDay = new Date(updatedClientRoute.periodicityDate).getDay();
            if (itemSelected !== undefined) {
                let result = await UpdateRoutesShippingAddressesAsync(clientRoute.id, data);
                if (result.isSuccessful) {
                    props.setDescription((prevClient) => {
                        const updatedClient = prevClient[props?.property].map((item) => {
                            if (item.id === props.itemSelected[props.property]) {
                                return updatedClientRoute;
                            }
                            return item;
                        });
                        return {
                            ...prevClient,
                            [props.property]: updatedClient,
                        };
                    });
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
            else {
                let result = await AddRoutesShippingAddressesAsync(data);
                if (result.isSuccessful) {
                    updatedClientRoute.id = result.data;
                    if (props?.description[props?.property]) {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [...prevClient[props.property], updatedClientRoute],
                        }));
                    }
                    else {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [updatedClientRoute],
                        }));

                    }
                    props.setSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: result.data
                    }));
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                    //setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }

            }
        }
    }
    const onBlurVisitingHours = (event) => {
        const text = event.target.value;
        if (RangeHours().some(x => x.text === text)) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                visitingHours: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                visitingHours: `Seleccione un horario de visita`
            }));
            event?.target?.focus();
        }
    };
    const onBlurDeliveryTime = (event) => {
        const text = event.target.value;
        if (RangeHours().some(x => x.text === text)) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                deliveryTime: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                deliveryTime: `Seleccione un horario de entrega`
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
                <OmniCard title='Datos generales'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                        <OmniCombobox
                                showSpinner={showSpinner}
                                title='Nombre del cliente'
                                items={client}
                                onChangeItemText={OnChangeClientText}
                                selected={clientRoute?.clientId}
                                value={clientRoute?.client}
                                onChangeItem={onChangeItemClient} required/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                        <OmniDropdown title='Dirección de envío' items={shippingAddress}
                            property="shippingAddressId" name="shippingAddress"
                            edit={setClientRoute} required
                            value={clientRoute?.shippingAddress}
                            selected={clientRoute?.shippingAddressId}
                            validationMessage={showErrors.shippingAddress} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                        <OmniDropdown items={GetPeriodicity()} title='Se repite'
                            selected={clientRoute?.periodicityId} required
                            value={GetPeriodicityById(clientRoute?.periodicityId)}
                            property="periodicityId" name="periodicity"
                            edit={setClientRoute} IncludeDefaultOption={false}
                        />
                        </div>
                        <div className='col-lg-6 col-md-12'>
                        <OmniDatePicker 
                            property="periodicityDate"
                            edit={setClientRoute}
                            minDate={new Date()}
                            maxDate={GetMaxDate()}
                            value={clientRoute?.periodicityDate}
                            restrictedDates={getRestrictedDates}
                            initialPickerDate={initialPickerDate.current}                        />
                        </div>
                    </div>
                    <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <OmniTimePicker title='Horario de visita'
                            property="visitingHours" 
                            edit={setClientRoute} required
                            value={clientRoute?.visitingHours}
                            maxLength={8}
                            validationMessage={showErrors.visitingHours} className="combo-omni-small"  
                            onBlur={onBlurVisitingHours} items={RangeHours()}/>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                        <OmniTimePicker title='Horario de entrega'
                            property="deliveryTime" className="combo-omni-small"
                            edit={setClientRoute} required
                            value={clientRoute?.deliveryTime} maxLength={8} validationMessage={showErrors.deliveryTime}
                            onBlur={onBlurDeliveryTime} items={RangeHours()} />
                        </div>
                </div>
                {clientRoute?.clientId &&
                    <OtherRoutes clientId={clientRoute?.clientId} day={props.day} title='Otras rutas del cliente' />
                }
                </OmniCard>
        </>
    )
}