import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { useEffect, useState } from 'react';
import { rightButtons, leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Client/ShippingAddresses/Add";
import { leftButtons as leftButtonsEdit } from "../../../../Assets/Constants/BarButtons/Catalog/Client/ShippingAddresses/Edit";
import {
    UpdateShippingAddressesLocationAsync
} from '../../../../Functions/Catalogs/Client/ClientStore'
import { Map } from './Map';
export const LocationAdd = (props) => {
    const { setListMessageCatalog } = useGeneralStatesContext();
    const itemSelected = (props.view[props.property].viewEdit ? props.description[props.property].find(x => x.id == [props?.itemSelected[props.property]]) : undefined);
    const [shippingAddresses, setShippingAddresses] = useState(itemSelected)
    const [showErrors, setShowErrors] = useState({
        latitude: undefined,
        longitude: undefined
    });
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [])
    function Return() {
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: false,
                viewDetail: true,
                viewEdit: false
            }
        }));
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: itemSelected !== undefined ? leftButtonsEdit : leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [shippingAddresses])
    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!shippingAddresses.longitude) {
            errorMessages.push("La longitud es obligatoria");
        }
        if (shippingAddresses.latitude && errorMessages.includes('La latitud es obligatoria')) {
            const index = errorMessages.indexOf('La latitud es obligatoria');
            if (index !== -1) {
                errorMessages.splice(index, 1);
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            const updatedItem = {
                ...shippingAddresses,
                clientId: props.clientId,
                active: true
            };
            let data = {
                id: updatedItem.id,
                latitude: parseFloat(updatedItem.latitude),
                longitude: parseFloat(updatedItem.longitude)
            };
            if (itemSelected !== undefined) {
                let result = await UpdateShippingAddressesLocationAsync(data);
                if (result.isSuccessful) {
                    props.setDescription((prevClient) => {
                        const updatedClient = prevClient[props?.property].map((item) => {
                            if (item.id === props.itemSelected[props.property]) {
                                return updatedItem;
                            }
                            return item;
                        });
                        return {
                            ...prevClient,
                            [props.property]: updatedClient,
                        };
                    });
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    const onBlurLatitude = (event) => {
        const inputTime = event.target.value;
        if (/^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/i.test(inputTime)) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                latitude: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                latitude: 'La latitud no es valida'
            }));
            event?.target?.focus();
        }
    };
    const onBlurLongitude = (event) => {
        const inputTime = event.target.value;
        if (/^-?((1[0-7]\d(\.\d+)?)|([1-9]?\d(\.\d+)?)|180(\.0+)?)$/i.test(inputTime)) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                longitude: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                longitude: 'La longitud no es valida'
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
            <OmniCard>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <OmniTextBox title="Latitud" focus
                            value={shippingAddresses?.latitude} maxLength={19}
                            edit={setShippingAddresses}
                            blur={onBlurLatitude}
                            property="latitude"
                            validationMessage={showErrors.latitude}                        />
                    </div>
                        <div className='col-lg-6 col-md-12'>
                        <OmniTextBox title="Longitud"
                            value={shippingAddresses?.longitude} maxLength={19}
                            edit={setShippingAddresses}
                            blur={onBlurLongitude}
                            property="longitude"
                            validationMessage={showErrors.longitude} />
                    </div>
                </div>
            </OmniCard>
            {shippingAddresses.latitude && shippingAddresses.longitude &&
                <OmniCard title='Ubicación en el mapa'>
                    {
                        <Map key={`${parseFloat(shippingAddresses.latitude)}-${parseFloat(shippingAddresses.longitude)}`}
                            latitude={parseFloat(shippingAddresses.latitude)} longitude={parseFloat(shippingAddresses.longitude)} />
                    }

                </OmniCard>
            }
        </>
    )
}