import {Text} from '@fluentui/react/lib/Text';
import { Stack } from '@fluentui/react/lib/Stack';
import { Player } from '@lottiefiles/react-lottie-player';
import omniLogoJson from "../../../Assets/JsonSvg/Common/omni.json";

const logoStackTokens = {
    childrenGap: '13px',
};


export default function Logo(){
    return(
        <Stack 
        horizontal 
        tokens={logoStackTokens} 
        verticalAlign='center'
        >
            <Stack.Item>
                <Player
                    autoplay
                    loop
                    src={omniLogoJson}
                    className="login-header-logo"
                />
            </Stack.Item>
            <Stack.Item>
                <Text
                className='header-text'>
                    Cuvitek Omni
                </Text>
            </Stack.Item>
        </Stack>
    );
}

