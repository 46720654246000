import { useEffect, useState } from "react";
import BarButtons from "../../Shared/BarButtons/BarButtons"
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Vehicles/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Vehicles/VehiclesStore'
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { Stack } from "@fluentui/react";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, useForeignKey } = useGeneralStatesContext();
    const [Vehicle, setVehicle] = useState({});
    const [status, setStatus] = useState('');
    const [leftButtonsDetail, setLeftButtonsDetail] = useState(leftButtons);
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setVehicle({
                alias: result.data.alias,
                brand: result.data.brand,
                model : result.data.model,
                year : result.data.year,
                color : result.data.color,
                sCTPermission: result.data.sctPermission,
                sCTNumberPermission: result.data.sctNumberPermission ,
                licensePlate : result.data.licensePlate,
                insuranceCarrier : result.data.insuranceCarrier,
                policyNumber : result.data.policyNumber,
                requiresPrivateTransportPermit: result.data.requiresPrivateTransportPermit,
                tons : result.data.tons,
                cubicMeters : result.data.cubicMeters
            });
            setStatus(!result.data.active);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, status);
        if (result.isSuccessful) {
            setStatus(!status);
            updateItemStatus(id);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
    }, [id])

    useEffect(() => {
        const updatedButtons = leftButtonsDetail.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton} el vehículo?`,
                    handler: () => { updateStatus() }
                };
            }
            return button;
        });
        setLeftButtonsDetail(updatedButtons);
    }, [status]);
    const profileStackTokens = {
        childrenGap: '80px',
    };
    const insuranceStackTokens = {
        childrenGap: '50px',
    };
    return (
        <>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtonsDetail} />
            <Stack horizontal>
                <Stack.Item >
                    <OmniCard title='Datos generales'>
                        {
                            useForeignKey &&
                            <Stack horizontal>
                                <Stack.Item >
                                    <OmniLabel text='Clave SAP' value={id} />
                                </Stack.Item>
                            </Stack>
                        }
                        <Stack horizontal>
                            <Stack.Item >
                                <OmniLabel text='Alias' value={Vehicle.alias} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal>
                            <Stack.Item >
                                <OmniLabel text='Marca' value={Vehicle.brand} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal >
                            <Stack.Item grow>
                                <OmniLabel text='Modelo' value={Vehicle.model} />
                            </Stack.Item>
                            <Stack.Item grow>
                                <OmniLabel text='Año' value={Vehicle.year} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal >
                            <Stack.Item grow>
                                <OmniLabel text='Color' value={Vehicle.color} />
                            </Stack.Item>
                            <Stack.Item grow>
                                <OmniLabel text='Placa' value={Vehicle.licensePlate} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal >
                            <Stack.Item grow>
                                <OmniLabel text='Toneladas' value={Vehicle.tons} />
                            </Stack.Item>
                            <Stack.Item grow>
                                <OmniLabel text='Metros cúbicos' value={Vehicle.cubicMeters} />
                            </Stack.Item>
                        </Stack>
                    </OmniCard>
                </Stack.Item>
                <Stack.Item >
                    <OmniCard title='Permisos'>
                        <Stack vertical>
                            <Stack.Item >
                                <OmniLabel text='Requiere permiso para transporte privado' value={Vehicle.requiresPrivateTransportPermit?"Si":"No"} />
                            </Stack.Item>
                            <Stack.Item >
                                <OmniLabel text='Permiso Secretaría de Comunicaciones y Transportes' value={Vehicle.sCTPermission} />
                            </Stack.Item>
                            <Stack.Item >
                                <OmniLabel text='Número de permiso' value={Vehicle.sCTNumberPermission} />
                            </Stack.Item>
                        </Stack>
                    </OmniCard>
                    <OmniCard title='Aseguradora'>
                        <Stack horizontal>
                            <Stack.Item grow>
                                <OmniLabel text='Empresa aseguradora' value={Vehicle.insuranceCarrier} />
                            </Stack.Item>
                            <Stack.Item grow>
                                <OmniLabel text='Número de póliza' value={Vehicle.policyNumber} />
                            </Stack.Item>
                        </Stack>
                    </OmniCard>
                </Stack.Item>
            </Stack>
           
        </>
    );
}