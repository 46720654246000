export const leftButtons: Buttons[] = [{
    key: 'LblTitle',
    text: 'Nueva huella del empleado',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const rightButtons = (handleClickSave, handleClickReturn) => [{
    key: 'BtnLeer',
    text: 'Leer',
    iconName: 'handPrintCuvitek',
    fontColor: 'var(--info)',
    iconColor: 'var(--info)',
    iconHover: 'var(--info)',
    backgroundColorButton: '#fff',
    backgroundColorButtonHover: '#fff',
    isButton: true,
    borderColor: 'var(--info) solid 2px',
    handler: handleClickSave
}
    , {
    key: 'BtnRegresar',
    text: 'Regresar',
    iconName: 'returnCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    handler: handleClickReturn
    }]
export const rightButtonsSave = (handleClickSave, handleClickReturn) => [{
    key: 'BtnGuardar',
    text: 'Guardar',
    iconName: 'saveCuvitek',
    fontColor: 'var(--success)',
    iconColor: 'var(--success)',
    iconHover: 'var(--success)',
    backgroundColorButton: '#fff',
    backgroundColorButtonHover: '#fff',
    isButton: true,
    borderColor: 'var(--success) solid 2px',
    handler: handleClickSave
}
    , {
    key: 'BtnRegresar',
    text: 'Regresar',
    iconName: 'returnCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    handler: handleClickReturn
}]