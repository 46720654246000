import React from "react";
import { Pivot, PivotItem, Stack, Image, SearchBox } from "@fluentui/react";
import Item from "./Item";
import { useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import StatusFilter from "../../../Assets/Constants/Enums/StatusFilter";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import './../../../Assets/Styles/Shared/ItemsSelector/ItemsSelector.css'
import { useEffect } from "react";
import { useState } from "react";
import  SkeletonItemSelector  from '../Skeleton/SkeletonItemSelector';

export default function ItemsList(props) {
    const axiosPrivate = useAxiosPrivate();
    const 
        {
        setItems,  
        statusFilter,
        setStatusFilter, 
        textFilter,
        setTextFilter,
        id,
        search
    }= useGeneralStatesContext();
    const [executeGet,setExecuteGet]=useState(props.searchActiveInRender);
    const[actualIndex, setActualIndex]=useState(0);
    
    function searchAutocomplete(event){
            let wordToSearch = event.target.value;
            let resultList = [];
            props.originalItems.forEach(item => {
                if (item.description.toString().toLowerCase().includes(wordToSearch.toLowerCase())) {
                    resultList.push(item);
                }
            });
        setItems(resultList);
    }
    function keyDowCaptureHandler(event){
        if (event.key.toString().toLowerCase() === "enter") {
            setTextFilter(event.target.value);
            setExecuteGet(true);
        }
    }
    function pivotHandler(item,ev){
        let status=item.key.toString().replace(".$","");
        setStatusFilter(status);
        setExecuteGet(true);
    }

    
    useEffect(() => {
        if (executeGet && textFilter) {
            props.setVisibleSkeleton(true);
            props.getItems();
        }
    }, [statusFilter, textFilter])
    return(
        <div
            className="itemsselector-container">
                <div className="itemsselector-title">
                    <div className="itemsselector-title-container">
                        <div
                            className="itemsselector-item-image">
                            <Image {...props.image}/>
                        </div>
                        <div
                            className="itemsselector-title-text">
                            {props.title}
                        </div>
                    </div>
                </div>
                <div
                    className="itemsselector-search-container">
                        <SearchBox 
                            className="itemsselector-search-input"
                            placeholder="Buscar"
                            onKeyDownCapture={keyDowCaptureHandler} />
                </div>
                <div>
                    <Pivot
                    onLinkClick={pivotHandler} className="pivot-center-search">
                    <PivotItem headerText="Activos" key={StatusFilter.Active}>
                            <span className="itemsselector-items-result">Resultados</span>
                        <div className="itemsselector-items-container">
                            {props.visibleSkeleton ?
                                <SkeletonItemSelector /> :
                                (props.items && props.items.length > 0 ? props.items.map((i) => (
                                    <Item
                                        key={i[props.omniKey]}
                                        id={i[props.omniKey]}
                                        description={i[props.omniValue]}
                                        textToAcronym={i[props.omniAcronym]}
                                        isSelected={i.id == id ? true : false}
                                        detailComponent={props.detailComponent}
                                        actualIndex={actualIndex}
                                        setActualIndex={setActualIndex}
                                    />
                                )) : search===true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                    : <p></p>
                                )
                            }
                               
                            </div>
                            
                        </PivotItem>
                        <PivotItem headerText="Inactivos" key={StatusFilter.Inactive}>
                            <span className="itemsselector-items-result">Resultados</span>
                        <div className="itemsselector-items-container">
                            {props.visibleSkeleton ?
                                <SkeletonItemSelector /> :
                                (props.items && props.items.length > 0 ? props.items.map((i)=>(
                                    <Item 
                                        key={i[props.omniKey]}
                                        id={i[props.omniKey]} 
                                        description={i[props.omniValue]}
                                        textToAcronym={i[props.omniAcronym]}
                                        isSelected={i.id==id ? true:false}
                                        detailComponent={props.detailComponent}
                                        />
                                )) : search === true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                    : <p></p>
                                )
                            }
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Todos" key={StatusFilter.All}>
                            <span className="itemsselector-items-result">Resultados</span>
                        <div className="itemsselector-items-container">
                            {props.visibleSkeleton ?
                                <SkeletonItemSelector/> :
                                (props.items && props.items.length > 0 ? props.items.map((i) => (
                                    <Item 
                                        key={i[props.omniKey]}
                                        id={i[props.omniKey]} 
                                        description={i[props.omniValue]}
                                        textToAcronym={i[props.omniAcronym]}
                                        isSelected={i.id==id ? true:false}
                                        detailComponent={props.detailComponent}
                                        />
                                )) : search === true ? <p className='itemsselector-item-no-result'>No se encontraron resultados</p>
                                    : <p></p>
                                )
                            }
                            </div>
                        </PivotItem>
                    </Pivot>
                </div>
        </div>
    )
}

