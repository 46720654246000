import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { Stack } from "@fluentui/react";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Add } from '../../../Shared/EmailList/Add';
import { useEffect, useState } from 'react';
import { OmniCheckBox } from '../../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniDropdown } from "../../../Shared/Controls/Dropdown/OmniDropdown";
import { rightButtons, leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Client/Contacts/Add";
import { leftButtons as leftButtonsEdit } from "../../../../Assets/Constants/BarButtons/Catalog/Client/Contacts/Edit";
import {
    UpdateContactAsync, AddContactsAsync
} from '../../../../Functions/Catalogs/Client/ClientStore'

import { PersonRegular } from "@fluentui/react-icons";
export const Contact = (props) => {
    const { setListMessageCatalog } = useGeneralStatesContext();
    const initialList = {
        listContactsEmail: [],
    };
    const itemSelected = (props.view[props.property].viewEdit ? props.description[props.property].find(x => x.id == [props?.itemSelected[props.property]]) : undefined);
    const [contact, setContact] = useState(itemSelected ?? initialList);
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        rol: undefined
    });
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [])
    function Return() {
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: false,
                viewDetail: true,
                viewEdit: false
            }
        }));
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: itemSelected !== undefined ? leftButtonsEdit : leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [contact])
    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!contact.rolId) {
            errorMessages.push("El rol es obligatorio");
        }
        if (contact.contactName && errorMessages.includes('El nombre de contacto es obligatorio')) {
            const index = errorMessages.indexOf('El nombre de contacto es obligatorio');
            if (index !== -1) {
                errorMessages.splice(index, 1);
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            const updatedItem = {
                ...contact,
                clientId: props.clientId,
                active: true
            };
            if (itemSelected !== undefined) {
                let result = await UpdateContactAsync(updatedItem);
                if (result.isSuccessful) {
                    props.setDescription((prevClient) => {
                        const updatedClient = prevClient[props?.property].map((item) => {
                            if (item.id === props.itemSelected[props.property]) {
                                return updatedItem;
                            }
                            return item;
                        });
                        return {
                            ...prevClient,
                            [props.property]: updatedClient,
                        };
                    });
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
            else {
                let result = await AddContactsAsync(updatedItem);
                if (result.isSuccessful) {
                    updatedItem.id = result.data;
                    if (props?.description[props?.property]) {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [...prevClient[props.property], updatedItem],
                        }));
                    }
                    else {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [updatedItem],
                        }));

                    }
                    props.setSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: result.data
                    }));
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                    //setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    const onBlurContactName = (event) => {
        const inputTime = event.target.value;
        if (inputTime) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                name: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                name: 'El nombre de contacto es obligatorio'
            }));
            event?.target?.focus();
        }
    };
    return (
        <OmniCard title='Datos generales'>
            <Stack>
                <Stack.Item >
                    <OmniTextBox required title="Nombre del contacto"
                        focus
                        maxLength={40} edit={setContact} 
                        blur={onBlurContactName}
                        property="contactName" 
                        value={contact?.contactName}
                        validationMessage={showErrors.name}
                        contentAfter={<PersonRegular />
                    }                    />
                </Stack.Item>
                <Stack.Item >
                    <OmniDropdown title='Rol' items={props.contactsRol}
                        property="rolId" name="rol"
                        edit={setContact} required
                        value={contact?.rol}
                        selected={contact?.rolId}
                        validationMessage={showErrors.rol} />
                </Stack.Item>
                <Stack.Item >
                    <OmniTextBox title="Teléfono celular"
                        value={contact?.cellPhone}
                        property="cellPhone" edit={setContact} customMask={"999-999-9999"}/>
                </Stack.Item>
                <Stack.Item >
                    <OmniCheckBox title="Se le envía comprobante de venta por correo"
                        propertyChecked={contact?.proofMail}
                        propertyId="proofMail"
                        edit={setContact}                   />
                </Stack.Item>
                <Add description={contact}
                    setDescription={setContact}
                    titleColum="Lista de correos" property="listContactsEmail"
                    titleTextBox="Correo electrónico" />
            </Stack>
        </OmniCard>
    )
}