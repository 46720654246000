import { OmniCallout } from '../../../Shared/Callout/OmniCallout';
import BarButtons from '../../../Shared/BarButtons/BarButtons';
import { HandprintList } from './HandprintList';
import { Handprint } from './Handprint';
import { HandprintDetail } from './HandprintDetail';
import { useState, useEffect } from 'react';
import { rightButtons  } from "../../../../Assets/Constants/BarButtons/Catalog/Employees/Handprints/Handprint";
import { leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Employees/Handprints/Detail";
import {
    leftButtonsEdit as editingButton,
    leftButtonsDetail
} from '../../../../Assets/Constants/BarButtons/Catalog/Employees/Editing';
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { DeleteAsync } from '../../../../Functions/Catalogs/Employees/EmployeeStore'
export const CatalogHandprint = (props) => {
    const [openCalloutClients, setOpenCalloutClients] = useState();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const Add = () => {
        props.setIsItemDisabled(true);
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: true,
                viewDetail: false
            }
        }));
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: {}
        }));
    }
    function Delete() {
        setButtonsChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnStatus"
                    ? { ...button, iconColor: 'var(--danger)' }
                    : button
            )
        }));
        setOpenCalloutClients(true);
    }
    const [buttonsChild, setButtonsChild]
        = useState({
            leftButtonsChild: leftButtons(Delete),
            rightButtonsChild: rightButtons(Add)
        });
    useEffect(() => {
        if (props?.client && props?.property) {
            if (props?.client[props?.property].length > 0) {
                //let showItem = props.itemSelected === undefined ? true : false;
                //let itemShow = props.itemSelected !== undefined
                //    ? props.itemSelected[props.property] === undefined ? true : false : true;
                //if (showItem || itemShow) {
                    props.setItemSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: props?.client[props?.property][0].id
                    }));
                    ChangeActive();
                    if (props.setView) {
                        props.setView((prevView) => ({
                            ...prevView,
                            [props.property]: {
                                viewAdd: false,
                                viewDetail: true,
                                viewEdit: false
                            }
                        }));
                    }
                //}
            }
            else {
                setButtonsChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: {},
                    rightButtonsChild: rightButtons(Add).map(button => {
                        if (button.key === "BtnNew") {
                            return { ...button, disabled: ((props.statusCatalog === true ? false : true)) };
                        }
                        else {
                            return button;
                        }
                    })
                }));
                props.setIsItemDisabled(false);

                props.setbuttonsCatalog(prevEmail => ({
                    ...prevEmail,
                    leftButtons: leftButtonsDetail,
                    rightButtons: {}
                }));
            }
        }
    }, [props.client]);
    useEffect(() => {
        if (props.view[props.property].viewDetail) {
            if (props.itemSelected && props.itemSelected[props.property] !== undefined) {
                    ChangeActive();
            }
            else {
                setButtonsChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: [],
                    rightButtonsChild: rightButtons(Add)
                }));
            }
            props.setIsItemDisabled(false);

            props.setbuttonsCatalog(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        }
    }, [props.view[props.property]]);
    const AllItemsOk = async () => {
        if (props?.itemSelected && props.itemSelected[props?.property]) {
            let updatedClient = [...props.client[props?.property]];
            let itemCurrent = updatedClient.find(x => x.id == [props.itemSelected[props.property]]);
            let result = await DeleteAsync(itemCurrent.id);
            if (result.isSuccessful) {
               updatedClient= updatedClient.filter(x => x.id != [props.itemSelected[props.property]])
                props.setClient((prevRoute) => {
                    return {
                        ...prevRoute,
                        [props.property]: updatedClient,
                    };
                });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
            setButtonsChild(prevState => ({
                ...prevState,
                leftButtonsChild: prevState.leftButtonsChild.map(button =>
                    button.key === "BtnStatus"
                        ? { ...button, iconColor: 'var(--secondary)' }
                        : button
                )
            }));
        }
        setOpenCalloutClients(false);
    }
    const AllItemsNo = async () => {
        setButtonsChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnStatus"
                    ? { ...button, iconColor: 'var(--secondary)' }
                    : button
            )
        }));
        setOpenCalloutClients(false);
    }
    useEffect(() => {
        if (props?.itemSelected && props?.property) {
                ChangeActive();
        }
    }, [props?.itemSelected && props?.itemSelected[props?.property]]);
    function ChangeActive() {
        setButtonsChild(prevState => ({
            ...prevState,
            leftButtonsChild: leftButtons(Delete).map(button => {
                if (button.key === "BtnStatus") {
                    return { ...button, disabled: ((props.statusCatalog === true ? false : true)) };
                }  else {
                    return button;
                }
            }),
            rightButtonsChild: rightButtons(Add).map(button => {
                if (button.key === "BtnNew") {
                    return { ...button, disabled: ((props.statusCatalog === true ? false : true)) };
                }
                else {
                    return button;
                }
            })
        }));
    }

    return (
        <>
            <BarButtons rightButtons={buttonsChild.rightButtonsChild}
                leftButtons={buttonsChild.leftButtonsChild} />

            <div className='container-fluid container-scroll-three-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <HandprintList description={props.client}
                            setSelected={props.setItemSelected}
                            itemSelected={props.itemSelected}
                            setView={props.setView}
                            property={props.property} />
                    </div>
                    <div className='col-md-auto'>
                        {props.view[props.property].viewAdd &&
                            <Handprint description={props.client}
                                setDescription={props.setClient}
                                setSelected={props.setItemSelected}
                                itemSelected={props.itemSelected}
                                view={props.view}
                                setView={props.setView}
                                setButtons={setButtonsChild}
                                property={props.property}
                                employeeId={props.employeeId} />
                        }
                        {((props.itemSelected !== undefined && props.itemSelected[props.property] !== undefined) && props.view[props.property].viewDetail) &&
                            <HandprintDetail description={props.client}
                                itemSelected={props.itemSelected} property={props.property} />
                        }
                    </div>
                </div>
            </div>
            {
                <OmniCallout
                    open={openCalloutClients}
                    updateIsOpenAlert={setOpenCalloutClients}
                    leftFunction={AllItemsOk}
                    rightFunction={AllItemsNo}
                    orientation={5}
                    title='Confirmación'
                    content={"¿Desea eliminar esta huella?"}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'white'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'white'}
                    colorRight={'var(--secondary)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    classNameLeft={'callOutButtonLeft'}
                    classNameRight={'callOutButtonRight'}
                    buttonId={"BtnStatus"}
                    barGroupKey={'leftCommandBar'}
                />
            }
        </>

    )
}