import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form'
import BarButtons from '../../Shared/BarButtons/BarButtons'
import { OmniCard } from '../../Shared/Card/OmniCard'
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox'
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Vehicles/Edit'
import { GetByIdAsync, UpdateAsync } from '../../../Functions/Catalogs/Vehicles/VehiclesStore'
import { useGeneralStatesContext, useIdSelectorContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { Stack } from "@fluentui/react";
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
export default function Edit() {
    const id = useIdSelectorContext();
    const { setListMessageCatalog, updateItem, useForeignKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const { register, handleSubmit } = useForm();
    const [Vehicle, setVehicle] = useState([]);
    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setVehicle({
                alias: result.data.alias,
                brand: result.data.brand,
                model: result.data.model,
                year: result.data.year,
                color: result.data.color,
                sCTPermission: result.data.sctPermission,
                sCTNumberPermission: result.data.sctNumberPermission,
                licensePlate: result.data.licensePlate,
                insuranceCarrier: result.data.insuranceCarrier,
                policyNumber: result.data.policyNumber,
                requiresPrivateTransportPermit: result.data.requiresPrivateTransportPermit,
                tons: result.data.tons,
                cubicMeters: result.data.cubicMeters
            });
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async (data) => {
        let result = await UpdateAsync(id, data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, id);
            data.id = id;
            updateItem(data);
            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        getById();
    }, [])
    return (
        <form onSubmit={handleSubmit(update)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />

            {Vehicle.length !== 0 &&
                (<>
                    <Stack horizontal>
                        <Stack.Item >
                        <OmniCard title='Datos generales'>
                            {
                                useForeignKey && <OmniLabel text='Clave SAP' value={id} />
                            }
                            <OmniTextBox required title="Alias" register={{ ...register("alias") }} focus value={Vehicle.alias} />
                            <OmniTextBox required title="Marca" register={{ ...register("brand") }} value={Vehicle.brand} />
                                <Stack horizontal >
                                    <Stack.Item >
                                    <OmniTextBox required width="90%" title="Modelo" register={{ ...register("model") }} value={Vehicle.model} />
                                    </Stack.Item>
                                    <Stack.Item >
                                    <OmniTextBox type='number' width="90%" title="Año" register={{ ...register("year") }} value={Vehicle.year} />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal >
                                    <Stack.Item >
                                        <OmniTextBox width="90%" title="Color" register={{ ...register("color") }} value={Vehicle.color} />
                                    </Stack.Item>
                                    <Stack.Item >
                                        <OmniTextBox width="90%" title="Placa" register={{ ...register("licensePlate") }} value={Vehicle.licensePlate} />
                                    </Stack.Item>
                                </Stack>
                                <Stack horizontal >
                                    <Stack.Item >
                                    <OmniTextBox type='number' width="90%" title="Toneladas" register={{ ...register("tons") }} value={Vehicle.tons} />
                                    </Stack.Item>
                                    <Stack.Item >
                                    <OmniTextBox type='number' width="90%" title="Metros cúbicos" register={{ ...register("cubicMeters") }} value={Vehicle.cubicMeters} />
                                    </Stack.Item>
                                </Stack>
                            </OmniCard>
                        </Stack.Item>
                        <Stack.Item >
                            <OmniCard title='Permisos'>
                                <Stack vertical>
                                    <Stack.Item >
                                    <OmniCheckBox title="Requiere permiso para transporte privado"
                                        register={{ ...register("requiresPrivateTransportPermit") }}
                                        propertyChecked={Vehicle.requiresPrivateTransportPermit}
                                        />
                                        <OmniTextBox title="Permiso Secretaría de Comunicaciones y Transportes" register={{ ...register("sCTPermission") }} value={Vehicle.sCTPermission} />
                                        <OmniTextBox title="Número de permiso" register={{ ...register("sCTNumberPermission") }} value={Vehicle.sCTNumberPermission} />
                                    </Stack.Item>
                                </Stack>
                            </OmniCard>
                            <OmniCard title='Aseguradora'>
                                <Stack horizontal >
                                    <Stack.Item >
                                        <OmniTextBox width="90%" title="Empresa aseguradora" register={{ ...register("insuranceCarrier") }} value={Vehicle.insuranceCarrier} />
                                    </Stack.Item>
                                    <Stack.Item >
                                        <OmniTextBox width="90%" title="Número de póliza" register={{ ...register("policyNumber") }} value={Vehicle.policyNumber} />
                                    </Stack.Item>
                                </Stack>
                            </OmniCard>
                        </Stack.Item>
                    </Stack>
                </>)}
        </form>
    )
}