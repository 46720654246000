import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Stack } from "@fluentui/react";
export const HandprintList = (props) => {
    const columnsHeaderRoutes = [
        { columnKey: "1", label: "Lista de huellas(" + (isNaN(props.description[props.property]?.length) ? 0 : props.description[props.property]?.length) + ")", width: '90%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '10%' }
    ];

    const changeComponentClik = (id) => {
        props.setSelected((prevClient) => ({
            ...prevClient,
            [props.property]: id
        }));
        if (props.setView) {
            props.setView((prevView) => ({
                ...prevView,
                [props.property]: {
                    viewAdd: false,
                    viewDetail: true
                }
            }));
        }
    };
    return (
        <Stack vertical>
            <Stack.Item >
                <OmniCard title='Huellas registradas'>
                    <div className="detail-table-scroll">
                        <Table className="detail-table">
                            <TableHeader>
                                <TableRow >
                                    {columnsHeaderRoutes.map((column) => (
                                        <TableHeaderCell key={column.columnKey}
                                            style={{ width: column.width }}
                                            className={'detail-table-header-title ' + column?.classAdd}>
                                            {column.label}
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {props.description[props.property] &&
                                    props.description[props.property].map((item, index) => (
                                        <TableRow key={index} onClick={() => changeComponentClik(item.id)}
                                            className={(props.itemSelected && item.id == props.itemSelected[props.property]) ? "item-table-selected" : "item-table"}>
                                            <TableCell >
                                                <TableCellLayout className='delete-table-body-description'>
                                                    Mano {item.hand} - Dedo {item.finger}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell >
                                                <TableCellLayout className="label-info">
                                                    {item.fingerprintType}
                                                </TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>
                </OmniCard>
            </Stack.Item>
        </Stack>
    )
}