import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout
} from "@fluentui/react-components";
import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import { useState, useEffect } from 'react';
import { GetRoutesByClient } from '../../../../Functions/Catalogs/Routes/RoutesStore'
import useAxiosPrivate from "../../../../Hooks/Common/useAxiosPrivate";
import { SetAxiosPrivate } from '../../../../Functions/Catalogs/Routes/RoutesStore'
export const OtherRoutes = (props) => {
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const columnsHeaderRoutes = [
        { columnKey: "1", label: props.title, width: '50%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '25%' },
        { columnKey: "3", label: "", width: '25%' }
    ];
    const [clientsRoutes, setClientsRoutes] = useState();
    useEffect(() => {
        OnLoadOtherRoutes();
    }, [props?.clientId]);
    const OnLoadOtherRoutes = async () => {
        let client = await GetRoutesByClient(props?.clientId)
        if (client.isSuccessful) {
            let clientList = client.data.filter(x => x.dayId != props?.day).map((item) => ({
                id: item.routeId,
                route: item.route,
                deliveryTime: item.deliveryTime,
                visitingHours: item.visitingHours
            }))
            if (clientList.length > 0) {
                setClientsRoutes(clientList);
            }
            else {
                setClientsRoutes(undefined);
            }
        }
        else {
            setClientsRoutes(undefined);
        }
    }
    return (
        <>
            {clientsRoutes && clientsRoutes.length>0 &&
                <div className="detail-table-scroll">
                    <Table className="detail-table">
                        <TableHeader>
                            <TableRow >
                                {columnsHeaderRoutes.map((column) => (
                                    <TableHeaderCell key={column.columnKey}
                                        style={{ width: column.width }}
                                        className={'detail-table-header-title ' + column?.classAdd}>
                                        {column.label}
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {clientsRoutes &&
                                clientsRoutes.map((item, index) => (
                                    <TableRow key={index}
                                        className={"item-table"}>
                                        <TableCell >
                                            <TableCellLayout >
                                                {item.route}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout  >
                                                {item.visitingHours}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {item.deliveryTime}
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
            }
        </>
    )
}