import { Pivot, PivotItem } from '@fluentui/react';
import { Switch } from "@fluentui/react-components";
import { Checkbox } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Warehouse/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Warehouse/WarehouseStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { Weekdays } from '../../../Models/Shared/DayOfWeek/Weekdays';
import '../../../Assets/Styles/Shared/Controls/OmniCheckBox.css';
import '../../../Assets/Styles/Shared/Controls/OmniSwitch.css';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton } = useGeneralStatesContext();
    const [warehouse, setWarehouse] = useState([]);
    const [buttonsWarehouse, setButtonsWarehouse] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getBranchs = async (cuvikBranchKey) => {
        if (cuvikBranchKey != '') {
            let result = await GetBranchsAsync();
            if (result.isSuccessful) {
                let key = result.data.find(branch => branch.brachKey == cuvikBranchKey);
                let keyresult = (key === undefined) ? '' : key.text;
                return keyresult;
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
        else { return cuvikBranchKey; }
    }

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            let branchName = result.data.cuvikBranchKey!= '' ? await getBranchs(result.data.cuvikBranchKey) : '';
            setWarehouse({
                id: result.data.id,
                name: result.data.name,
                warehouseKey: result.data.warehouseKey,
                isWarehouse: result.data.isWarehouse,
                cuvikBranchKey: result.data.cuvikBranchKey,
                cuvikBranchName: branchName,
                dailyReceptions: result.data.dailyReceptions.toLocaleString(),
                maximumTons: result.data.maximumTons.toLocaleString(),
                maximumVolume: result.data.maximumVolume.toLocaleString(),
                maximumBoxes: result.data.maximumBoxes.toLocaleString(),
                inventoryDays: result.data.inventoryDays.toLocaleString(),
                //restockWarehouse: result.data.restockWarehouse,
                listDaysReceiveWarehouseResult: result.data.listDaysReceiveWarehouseResult,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !warehouse.active);
        if (result.isSuccessful) {
            setWarehouse((prevWarehouse) => {
                return {
                    ...prevWarehouse,
                    active: !warehouse.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = warehouse.active ? "Inactivar" : "Activar";
                let statusiconbutton = warehouse.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = warehouse.active ? 19 : 200;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el almacén?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !warehouse.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }

    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);
    
    useEffect(() => {
        if (warehouse) {
            setButtonsWarehouse(prevWarehouse => ({
                ...prevWarehouse,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...warehouse, acronym: localStorage.getItem("userName") });
        }
    }, [warehouse?.active]);

    useEffect(() => {
        if (warehouse) {
            setButtonsWarehouse(prevWarehouse => ({
                ...prevWarehouse,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus, id]);

    return (
        <>
            {warehouse.length !== 0 &&
                (<BarButtons rightButtons={buttonsWarehouse.rightButtons}
                    leftButtons={buttonsWarehouse.leftButtons} />)
            }
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" >
                    <div className='container-fluid container-scroll'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Clave' value={warehouse.warehouseKey} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Nombre' value={warehouse.name} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {
                                            warehouse.isWarehouse &&
                                            <div className='col-lg-12 col-md-12'>
                                                <Checkbox className='check-omni-disabled' size='large' disabled label='Este almacén es bodega' checked />
                                            </div>
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Sucursal' value={warehouse.cuvikBranchName} />
                                        </div>
                                    </div>
                                </OmniCard>
                                {/*{*/}
                                {/*    warehouse.restockWarehouse &&*/}
                                {/*    <OmniCard title='Resurtir almacén'>*/}
                                {/*        <div className='row'>*/}
                                {/*            <div className='col-lg-12 col-md-12'>*/}
                                {/*                <Checkbox className='check-omni-disabled' size='large' disabled label='Se resurten todos los artículos de este almacén' checked />*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </OmniCard>*/}
                                {/*}*/}
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Capacidad de recibo'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Recepciones por día' value={warehouse.dailyReceptions} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Toneladas por día' value={warehouse.maximumTons} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Volúmen por día' value={warehouse.maximumVolume} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Cajas por día' value={warehouse.maximumBoxes} />
                                        </div>
                                    </div>
                                </OmniCard>
                                <OmniCard title='Resurtido'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='Días de inventario' value={warehouse.inventoryDays} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {
                                            warehouse.listDaysReceiveWarehouseResult &&
                                            Weekdays.map(({ number, name }) => (
                                                warehouse.listDaysReceiveWarehouseResult.some((item) => item.day === number) && (
                                                    <div className='col-lg-4 col-md-12' key={name}>
                                                        <Switch key={name} checked disabled label={name} className='switch-omni-disabled' />
                                                    </div>
                                                )
                                            ))
                                        }
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes">
                    <DetailChanges user={warehouse} />
                </PivotItem>
            </Pivot>
            
        </>
    )
}