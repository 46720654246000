import { LoginBox } from "./LoginBox"
import '../../Assets/Styles/Authentication/Login.css'
import { Stack } from '@fluentui/react/lib/Stack';
import { Player } from '@lottiefiles/react-lottie-player';
import omniLogoJson from "../../Assets/JsonSvg/Common/omni.json";
import { CustomerWidget } from "./CustomerWidget";
import { OmniSpeedTest } from "../Shared/SpeedTest/OmniSpeedTest";
export function Login() {
    return (
        <>
            <Stack enableScopedSelectors vertical className="mainStackContainer" >
                <Stack.Item className="login-header">
                    <Stack horizontal verticalAlign="center" horizontalAlign="space-between" verticalFill gap={13} padding={9}>
                        <Stack.Item >
                            <Stack horizontal verticalAlign="center">
                                <Stack.Item>
                                    <Player autoplay loop src={omniLogoJson} className="login-header-logo" ></Player>
                                </Stack.Item>
                                <Stack.Item>
                                    <span className="login-header-label">Cuvitek Omni</span>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>

                        <Stack.Item align="end" className="speed-test-container">
                            <OmniSpeedTest></OmniSpeedTest>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack enableScopedSelectors horizontal>
                        <Stack.Item grow align="start" className="customer-stack">
                            {/*<OmniInternetSpeed></OmniInternetSpeed>*/}
                            <CustomerWidget></CustomerWidget>
                        </Stack.Item>
                        <Stack.Item align="end" className="box-stack">
                            <LoginBox />                   
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </>
    );
}
