import Detail from '../../../../../Components/Catalogs/Creditors/Detail';
export const leftButtons = [{
    key: 'LblTitle',
    text: 'Editar acreedor',
    fontColor: 'var(--primary)',
    fontSize: '16px',
    isText: true
}];
export const rightButtons = [{
    key: 'BtnGuardar',
    text: 'Guardar',
    iconName: 'saveCuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--success)',
    backgroundColorButtonHover: 'var(--success)',
    isButton: true,
    type: 'submit'
}
    , {
    key: 'BtnRegresar',
    text: 'Regresar',
    iconName: 'returnCuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Detail />
}]