import { OmniCard } from '../Card/OmniCard';
import { OmniLabelTooltip } from '../Controls/LabelTooltip/OmniLabelTooltip';
export const Detail = (props) => {
    function toLongDate(dateParam) {
        if (dateParam) {
            const date = new Date(dateParam);
            const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
            hours = hours % 12;
            hours = hours ? hours : 12;
            const minutesFormat = minutes < 10 ? '0' + minutes : minutes;
            const secondFormat = seconds < 10 ? '0' + seconds : seconds;
            const formatodate = `${day} de ${month} de ${year}, ${hours}:${minutesFormat}:${secondFormat} ${ampm}`;
            return formatodate;
        }
        return "";
    }
    function toShortDate(dateParam) {
        if (dateParam) {
            const date = new Date(dateParam);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const dayFormat = day < 10 ? '0' + day : day;
            const monthFormat = month < 10 ? '0' + month : month;
            const dateFormateada = `${dayFormat}/${monthFormat}/${year}`;
            return dateFormateada;
        }
        return "";
    }

    return (
        <>
            <div className='container-fluid container-scroll-two-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <OmniCard title='Datos generales'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniLabelTooltip text='Alta' value={toShortDate(props?.user?.createDate)} infoText={toLongDate(props?.user?.createDate)} />
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniLabelTooltip text='Usuario creador' value={props?.user?.createUserName} infoText={props?.user?.createUserFullName} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniLabelTooltip text='Último cambio' value={toShortDate(props?.user?.lastUpdateDate)} infoText={toLongDate(props?.user?.lastUpdateDate)} />
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniLabelTooltip text='Último usuario' value={props?.user?.updateUserName} infoText={props?.user?.updateUserFullName} />
                                </div>
                            </div>
                        </OmniCard>
                    </div>
                </div>
            </div>
        </>
    )
}