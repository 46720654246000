import { useEffect, useState } from 'react';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Families/Detail';
import { useGeneralStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Families/FamilyStore'
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import '../../../Assets/Styles/Shared/Controls/OmniCheckBox.css';
import '../../../Assets/Styles/Shared/Controls/OmniSwitch.css';
import { Pivot, PivotItem } from '@fluentui/react';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { ItemsTable } from '../../Shared/ItemsTable/ItemsTable';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton } = useGeneralStatesContext();
    const [family, setFamily] = useState([]);
    const [buttonsFamily, setButtonsFamily] = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const [changestatus, setChangestatus] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);

    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setFamily({
                id: result.data.id,
                name: result.data.name,
                items: result.data.items,
                active: result.data.active,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, !family.active);
        if (result.isSuccessful) {
            setFamily((prevFamily) => {
                return {
                    ...prevFamily,
                    active: !family.active,
                };
            });
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = family.active ? "Inactivar" : "Activar";
                let statusiconbutton = family.active ? "inactiveCuvitek" : "activeCuvitek";
                let updatedAuthorizationKey = family.active ? 189 : 190;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} la familia?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: !family.active,
                };
            }
            return button;
        });
        return updatedButtons;
    }

    useEffect(() => {
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id]);

    useEffect(() => {
        if (family) {
            setButtonsFamily(prevFamily => ({
                ...prevFamily,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            updateItemStatus({ ...family, acronym: localStorage.getItem("userName") });
        }
    }, [family?.active]);

    useEffect(() => {
        if (family) {
            setButtonsFamily(prevFamily => ({
                ...prevFamily,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus,id]);

    return (
        <>
            {family.length !== 0 &&
                (<BarButtons rightButtons={buttonsFamily.rightButtons}
                    leftButtons={buttonsFamily.leftButtons} />)
            }
            <div className='container-fluid container-scroll'>
                <div className='row'>
                    <Pivot className="pivot-center-client" >
                        <PivotItem headerText="Información" itemKey="information" >
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-12'>
                                                    <OmniLabel text='Nombre' value={family.name} />
                                                </div>
                                                <div className='col-lg-6 col-md-12'>

                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Artículos" itemKey="items">
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Artículos  '>
                                            <div id='divItemsContainer' className='row card-items-container'>
                                                <div className='col-lg-12 col-md-12'>
                                                    <ItemsTable titleheader='la familia' items={family.items} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText="Cambios" itemKey="changes" >
                            <DetailChanges user={family} />
                        </PivotItem>
                    </Pivot>
                </div>
            </div>
        </>
    )
}