export class DangerMessage {
    constructor(text = "", typeColor = 'var(--danger-emphasis)', iconOption = require('../../../Assets/Images/Icons/InactiveRed.svg').default) {
        this.text = text;
        this.typeColor = typeColor;
        this.iconOption = iconOption;
    }
}
export class PrimaryMessage {
    constructor(text = "", typeColor = 'var(--primary-emphasis)', iconOption = require('../../../Assets/Images/Icons/Active.svg').default) {
        this.text = text;
        this.typeColor = typeColor;
        this.iconOption = iconOption;
    }
}
export class SuccesMessage {
    constructor(text = "", typeColor = 'var(--success-emphasis)', iconOption = require('../../../Assets/Images/Icons/Ok.svg').default) {
        this.text = text;
        this.typeColor = typeColor;
        this.iconOption = iconOption;
    }
}