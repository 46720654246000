import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
export const HandprintDetail = (props) => {
    const itemSelected = props.description[props.property].find(x => x.id == [props.itemSelected[props.property]]);
    return (
        <>
            {itemSelected &&
                <OmniCard title='Datos generales'>
                    <OmniLabel text='Mano' value={itemSelected?.hand} />
                    <OmniLabel text='Dedo' value={itemSelected?.finger} />
                    <OmniLabel text='Lector' value={itemSelected?.fingerprintType} />
                </OmniCard>
            }
        </>
    )
}