import '../../../Assets/Styles/Shared/Table/DetailTable.css';
import { Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow } from '@fluentui/react-components';
import { ItemStatus } from '../../../Assets/Constants/Enums/ItemStatus';

export const ItemsTable = (props) => {
    return (
        <>
            <Table className="detail-table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell className='detail-table-header-title-left'>
                            Artículos en { props.titleheader }
                        </TableHeaderCell>
                        <TableHeaderCell className='detail-table-header-title'>
                            Estatus
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        props.items?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell >
                                    <TableCellLayout >
                                        {item.description}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell className='detail-table-cell'>
                                    <TableCellLayout>
                                        {ItemStatus.find(status => status.status === item.status)?.show}
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>);
    
 }

