import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout, Button, Image, Tooltip, makeStyles, Text
} from "@fluentui/react-components";
import "../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useRef, useEffect, useState } from 'react';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"
import { OmniTextBox } from "../Controls/TextBox/OmniTextBox";
import { DangerMessage } from "../../../Models/Shared/BarMessages/Messages";
import { useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
const trashIcon = require('../../../Assets/Images/Icons/Trash.svg').default;
const stylesTooltipAll = makeStyles({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
    }
});
export const Add = (props) => {
    const columnsHeaderEmails = [
        { columnKey: "1", label: props.titleColum, width: '70%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '15%' },
    ];
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const stylesTooltip = stylesTooltipAll();
    const fieldEdited = useRef(false);
    const initialFieldValue = useRef(GetInitialSelectedOption());
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog } = useGeneralStatesContext();
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.description[props.property]) {
            initialSelectedOpction = props?.description[props.property].length
        }
        else {
            initialSelectedOpction = 0;
        }
        return initialSelectedOpction;
    };
    function DeleteItem(index) {
        const filteredList = props.description[props.property].filter((itemfilter, indexPrimary) => indexPrimary !== index);
        props.setDescription(prevEmail => ({ ...prevEmail, [props.property]: filteredList }));
        ChangeFieldEdit(filteredList.length);
    }
    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === props.property)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: props.property, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === props.property) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }

    }, [fieldEdited.current]);
    function keyDowCaptureHandler(event, inputDefault) {
        if (event?.key?.toString()?.toLowerCase() === "enter" || inputDefault) {
            if (event.target.value) {
                const email = event.target.value;
                const descripcionExistente = props.description[props.property].some((exist) => exist.description.toLowerCase() === email.toLowerCase());
                if (!descripcionExistente) {
                    const isValid = emailPattern.test(email);
                    if (isValid) {
                        const updatedList = [...props.description[props.property], { description: email }];
                        props.setDescription(prevEmail => ({ ...prevEmail, [props.property]: updatedList }));
                        ChangeFieldEdit(updatedList.length);
                    } else {
                        setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("El correo no es valido")]);
                    }
                } else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("El correo ya se encuentra registrado")]);
                }
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Debe escribir un correo")]);
            }
        }
    }
    function ChangeFieldEdit(length) {
        if (!props.inactivefieldEdited) {
            if (initialFieldValue.current === length) {
                fieldEdited.current = false;
            }
            else {
                fieldEdited.current = true;
            }
        }
    }
    const [showErrors, setShowErrors] = useState({
        email: undefined
    });
    const onBlurEmail = (event) => {
        const inputTime = event.target.value;
        if (!inputTime || emailPattern.test(inputTime)) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                email: undefined
            }));
            if (inputTime) {
                keyDowCaptureHandler(event, true);
            }
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                email: 'El correo no es válido'
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
            <OmniTextBox title={props.titleTextBox}
                onKeyDownCapture={(event) => keyDowCaptureHandler(event, false)}
                inactivefieldEdited
                blur={onBlurEmail}
                validationMessage={showErrors.email}
                contentAfter={
                    <Text size={500} >
                        @
                    </Text>
                } maxLength={50} notuppercase={true} cleanOnblur />

            <div className="detail-table-scroll header-iconEdit">
                {
                    fieldEdited.current === true &&
                    <div className="omniinfolabel-icon">
                        <Image alt="" src={require("../../../Assets/Images/Icons/Edit.svg").default} />
                    </div>
                }
                <Table className="detail-table">
                    <TableHeader>
                        <TableRow>
                            {columnsHeaderEmails.map((column) => (
                                <TableHeaderCell key={column.columnKey}
                                    style={{ width: column.width }}
                                    className={'detail-table-header-title ' + column?.classAdd}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.description[props.property] &&
                            props.description[props.property].map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell >
                                        <TableCellLayout className='delete-table-body-description'>
                                            {item.description}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout >
                                            <Tooltip
                                                withArrow
                                                positioning="after"
                                                content={{ children: "Eliminar", className: stylesTooltip.tooltip }}>
                                                <Button icon={<Image src={trashIcon} alt="delete" />} onClick={() => DeleteItem(index)} />
                                            </Tooltip>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}