import Add from '../../../../../Components/Catalogs/Warehouses/Add';
export const rightButtons= [
    {
        key: 'BtnNew',
        text: 'Nuevo',
        iconName: 'addcuvitek',
        fontColor: '#FFFFFF',
        iconColor: '#fff',
        iconHover: '#fff',
        backgroundColorButton: 'var(--primary)',
        backgroundColorButtonHover: 'var(--primary)',
        isButton: true,
        component: <Add />,
        authorizationKey: 17,
    }
]