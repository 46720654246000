import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import Detail from "./Detail";
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Families/Default";
import {ItemSelectorProvider} from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { FieldChangeWatcherProvider } from "../../../Context/Controls/FieldChangeWatcherProvider";
import { Index } from './Index';
const GetByStatus = '/family/getbystatus';
const iconCatalog= {
    src: require('../../../Assets/Images/Icons/Catalog.svg').default
}
export default function Family(){ 
    return (
        <ItemSelectorProvider >
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de familias"}
                    image={iconCatalog}
                    urlGetItems={GetByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail/>}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"name"}
                    omniAcronym={"acronym"}
                    getInLoad={false}
                    indexComponent={<Index />}
                    />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )   
}
