import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
import { GetPeriodicityById, GetDescriptionDayOfWeek } from '../../../../Functions/Catalogs/Routes/RoutesStore'
import { OtherRoutes } from './OtherRoutes';
export const ClientRouteDetail = (props) => {
    const itemSelected = props.description[props.property].find(x => x.id == [props.itemSelected[props.property]]);
    return (
        <>
            {itemSelected &&
                <OmniCard title='Datos generales'>
                    <OmniLabel text='Nombre del cliente' value={itemSelected?.client} />
                    <OmniLabel text='Dirección de envío' value={itemSelected?.shippingAddress} />
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <OmniLabel text='Repetición' value={GetPeriodicityById(itemSelected?.periodicityId) + ' el ' + GetDescriptionDayOfWeek(itemSelected?.periodicityDateDay)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                            <OmniLabel text='Horario de visita' value={itemSelected?.visitingHours} />
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <OmniLabel text='Horario de entrega' value={itemSelected?.deliveryTime} />
                        </div>
                    </div>
                    {itemSelected?.clientId &&
                        <OtherRoutes clientId={itemSelected?.clientId} day={props.day} title='Otras rutas del cliente' />
                    }
                </OmniCard>
            }
        </>
    )
}