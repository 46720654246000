import Tax from './Components/Catalogs/AccountingTaxes/Tax'
import Brand from './Components/Catalogs/Brand/Brand';
import Categories from './Components/Catalogs/Categories/Categories'
import Client from './Components/Catalogs/Client/Client';
import CompanyAsset from './Components/Catalogs/CompanyAssets/CompanyAsset'
import Creditor from "./Components/Catalogs/Creditors/Creditor";
import CreditorAccount from "./Components/Catalogs/CreditorAccount/CreditorAccount";
import CreditorSubAccount from "./Components/Catalogs/CreditorSubAccount/CreditorSubAccount";
import Driver from "./Components/Catalogs/Drivers/Driver";
import Employee from './Components/Catalogs/Employees/Employee';
import Extent from './Components/Catalogs/Extents/Extent';
import Family from "./Components/Catalogs/Families/Family";
import Home from "./Components/Home/Home";
import Routes from './Components/Catalogs/Routes/Routes';
import Variety from './Components/Catalogs/Varieties/Variety';
import Vehicles from './Components/Catalogs/Vehicles/Vehicles';
import Warehouse from './Components/Catalogs/Warehouses/Warehouse';
import Supplier from './Components/Catalogs/Suppliers/Supplier';

const AppRoutes = [
    {
        path:'catalogs/accountingtaxes',
        element:<Tax/>
    },
    {
        path: '/catalogs/brand',
        element: <Brand />
    },
    {
        path: 'catalogs/client',
        element: <Client />
    },
    {
        path: 'catalogs/categories',
        element: <Categories />
    },
    {
        path: 'catalogs/companyasset',
        element: <CompanyAsset />
    },
    {
        path: 'catalogs/creditor',
        element: <Creditor/>
    },
    {
        path: 'catalogs/creditoraccount',
        element: <CreditorAccount />
    },
    {
        path: 'catalogs/creditorsubaccount',
        element: <CreditorSubAccount />
    },
    {
        path: 'catalogs/driver',
        element: <Driver />
    },
    {
        path: 'catalogs/employees',
        element: <Employee />
    },
    {
        path: 'catalogs/extents',
        element: <Extent />
    },
    {
        path: 'catalogs/family',
        element: <Family />
    },
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: 'catalogs/routes',
        element: <Routes />
    },
    //{
    //    path: 'catalogs/suppliers',
    //    element: <Supplier />
    //},
    {
        path: '/catalogs/variety',
        element:<Variety/>
    },
    {
        path: 'catalogs/vehicles',
        element: <Vehicles />
    },
    {
        path: 'catalogs/warehouse',
        element: <Warehouse />
    },
];

export default AppRoutes;
