import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';
import { OmniSwitch } from '../../Shared/Controls/Switch/OmniSwitch';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Warehouse/Edit';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Warehouse/WarehouseStore';
import { GetBranchsAsync, } from '../../../Functions/Branchs/BranchStore';
import Detail from './Detail';
import { Weekdays } from '../../../Models/Shared/DayOfWeek/Weekdays';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';

export default function Edit() {
    const { register, handleSubmit, control, getValues } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [warehouseInformation, setWarehouseInformation] = useState([]);
    const [listBranch, setListBranch] = useState();
    SetAxiosPrivate(axiosPrivate);
    

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };

    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const getBranchs = async () => {
        let result = await GetBranchsAsync();
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        var data = getValues();
        if (data) {
            data.maximumBoxes = data.maximumBoxes && data.maximumBoxes !== ''
                ? data.maximumBoxes.replace(",", "")
                : data.maximumBoxes;
        }
        let result = await UpdateAsync(id, data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, id);
            data.id = id;
            updateItem({ ...data, acronym: localStorage.getItem("userName") });
            setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {        
        const fillData = async () => {
            try {
                const dataWarehouse = await getById();
                const dataBranchs = await getBranchs();
                setWarehouseInformation(dataWarehouse);
                setListBranch(dataBranchs);
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                {
                    <Pivot className="pivot-center-client" >
                        <PivotItem headerText="Información" itemKey="information" >              
                        <div className='container-fluid container-scroll'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        {warehouseInformation.length !== 0 && (<>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-12'>
                                                        <OmniLabel text='Clave' value={warehouseInformation.warehouseKey} />
                                                </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox required width="85%" title='Nombre' maxLength={64} register={{ ...register('name') }} value={warehouseInformation.name} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCheckBox title='Este almacén es bodega' register={{ ...register('isWarehouse') }}
                                                    propertyChecked={warehouseInformation.isWarehouse} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                {listBranch ?
                                                    <OmniSelect required width='100%' title='Sucursal' register={{ ...register('cuvikBranchId') }} items={listBranch} value={warehouseInformation.cuvikBranchId + '#' + warehouseInformation.cuvikBranchKey} />
                                                    :
                                                    <OmniSelect required disabled width='100%' title='Sucursal' register={{ ...register('cuvikBranchId') }} items={null} />
                                                }
                                            </div>
                                            </div>
                                        </>)}
                                    </OmniCard>
                                    {/*<OmniCard title='Resurtir almacén'>*/}
                                    {/*    {warehouseInformation.length !== 0 && (<>*/}
                                    {/*    <div className='row'>*/}
                                    {/*        <div className='col-lg-12 col-md-12'>*/}
                                    {/*            <OmniCheckBox title='Se resurten todos los artículos de este almacén' register={{ ...register('restockWarehouse') }}*/}
                                    {/*                propertyChecked={warehouseInformation.restockWarehouse} />*/}
                                    {/*        </div>*/}
                                    {/*        </div>*/}
                                    {/*    </>)}*/}
                                    {/*</OmniCard>*/}
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Capacidad de recibo'>
                                        {warehouseInformation.length !== 0 && (<>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox width="85%" inputType="number" length="4"  title='Recepciones por día' register={{ ...register('dailyReceptions') }} value={warehouseInformation.dailyReceptions.toString()} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox width="85%" inputType="number" length="4"   title='Toneladas por día' register={{ ...register('maximumTons') }} value={warehouseInformation.maximumTons.toString()} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox width="85%" inputType="number" length="4"  title='Volúmen por día' register={{ ...register('maximumVolume') }} value={warehouseInformation.maximumVolume.toString()} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox width="85%" inputType={'number'} length="8" title='Cajas por día' register={{ ...register('maximumBoxes') }} value={warehouseInformation.maximumBoxes.toString()} />
                                            </div>
                                        </div>
                                         </>)}
                                    </OmniCard>

                                    <OmniCard title='Resurtido'>
                                        {warehouseInformation.length !== 0 && (<>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox inputType="number" length="4" width="85%" title='Días de inventario' register={{ ...register('inventoryDays') }} value={warehouseInformation.inventoryDays.toString()} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            {Weekdays.map((day) => (
                                                <Controller
                                                    key={day.number}
                                                    name={`listDaysReceiveWarehouseResult[${day.number}]`}
                                                    control={control}
                                                    defaultValue={warehouseInformation.listDaysReceiveWarehouseResult.some((d) => d.day == day.number)}
                                                    render={({ field: { onChange, onBlur, value } }) => (
                                                        <div className='col-lg-4 col-md-12'>
                                                            <OmniSwitch
                                                                checked={value}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                label={day.name} />
                                                        </div>
                                                    )}
                                                />
                                            ))}
                                            </div>
                                        </>)}
                                    </OmniCard>
                                </div>
                            </div>
                        </div>                          
                        </PivotItem>
                        <PivotItem headerText="Cambios" headerButtonProps={{
                            'disabled': true,
                            'style': { color: 'grey' }
                        }}>
                        </PivotItem>   
                    </Pivot>
                }
            </form>
        {
        <OmniCallout
            open={openCallout}
            updateIsOpenAlert={setOpenCallout}
            leftFunction={update}
            title='Confirmación'
            content='¿Desea guardar los cambios?'
            leftButton={'Si'}
            rightButton={'Regresar'}
            backgroundColorLeft={'var(--success)'}
            colorLeft={'white'}
            backgroundColorRight={'var(--secondary)'}
            colorRight={'white'}
            buttonId={'BtnGuardar'}
            barGroupKey={'rightCommandBar'} 
        />
    }</>
    );
}