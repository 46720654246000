import Add from "../../../../../Components/Catalogs/Client/Add";
import Edit from "../../../../../Components/Catalogs/Client/Edit";

export const leftButtons: Buttons[] = [{
    key: 'BtnEdit',
    text: 'Editar',
    iconName: 'editcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    component: <Edit />,
    authorizationKey: 3,
}, {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    authorizationKey: 4,
}, {
    key: 'BtnBlock',
    text: 'Bloquear',
    iconName: 'blockcuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    authorizationKey: 623,
}
];
export const rightButtons: Buttons[] = [{
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    authorizationKey: 2,
}]