import { React } from 'react';
import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Creditor/Creditor';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';

const GetByStatus = '/creditor/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Creditor.svg').default
}
export default function Creditor() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de acreedores"}
                    image={iconCatalog}
                    urlGetItems={GetByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"description"}
                    getInLoad={true}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}