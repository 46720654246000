import { useState, useEffect, } from 'react';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Creditor/Detail';
import { useIdSelectorContext, useGeneralStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Edit from './Edit';
const statusUrl = '/creditor/SetStatus';
const getCreditorUrl = '/creditor/GetById';

export default function Detail() {
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const [status, setStatus] = useState('');
    const [creditorInformation, setCreditorInformation] = useState([]);
    const leftButtons = [
        {
            key: 'BtnEdit',
            text: 'Editar',
            iconName: 'editcuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--primary)',
            component: <Edit />
        },
        {
            key: 'BtnStatus',
            text: 'Inactivar',
            iconName: 'inactivecuvitek',
            iconColor: 'var(--secondary)',
            iconHover: 'var(--primary)',
            backgroundColorButton: '',
            backgroundColorButtonHover: '',
            handler: () => { onChangeStatus(); }
        }
    ];    
    const [leftButtonsDetail, setLeftButtonsDetail] = useState(leftButtons);
    const GetCreditor = async () => {
        try {
            const response = await axiosPrivate.get(getCreditorUrl, { params: { id: id } });
            if (response.data.isSuccessful) {
                setCreditorInformation({
                    name: response.data.data.name,
                    rfc: response.data.data.rfc,
                    creditoraccountdescription: response.data.data.creditorAccountDescription,
                    creditorsubaccountdescription: response.data.data.creditorSubAccountDescription,
                    creditoraccountid: response.data.data.creditorAccountId,
                    creditorsubaccountid: response.data.data.creditorSubAccountId,
                    //active: response.data.active
                });
                setStatus(!response.data.data.active);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }
        }
        catch (err)
        {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    };
    const onChangeStatus = async () => {
        try {
            let statustext = status ? "activado" : "desactivado";
            const response = await axiosPrivate
                .put(statusUrl,
                    JSON.stringify({
                        id: id,
                        active: status,
                        loggedUserName: localStorage.getItem("userName")
                    }));
            if (response.data.isSuccessful) {
                setStatus(!status);
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(`El acreedor ha sido ${statustext} exitosamente`,)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            } 
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    }
    useEffect(() => {
        const updatedButtons = leftButtonsDetail.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton,
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} el acreedor?`,
                    handler: () => { onChangeStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: status,
                };
            }
            return button;
        });
        setLeftButtonsDetail(updatedButtons);
    }, [status]);
    useEffect(() => {
        GetCreditor();
    }, [id]);

    return (
        <>
            <BarButtons rightButtons={rightButtons} leftButtons={leftButtonsDetail} />
            <OmniCard title='Datos generales'>
                <OmniLabel text='Razón social' value={creditorInformation.name} />
                <OmniLabel text='RFC' value={creditorInformation.rfc} />
                <OmniLabel text='Rubro' value={creditorInformation.creditoraccountdescription} />
                <OmniLabel text='Subrubro' value={creditorInformation.creditorsubaccountdescription} />
            </OmniCard>          
        </>
    );
}
