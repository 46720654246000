import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';

import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniSelect } from '../../Shared/Controls/Select/OmniSelect';

import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Creditor/Edit';
import Detail from './Detail';

import { useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';

const updateCreditorUrl = '/creditor/update';
const getCreditorUrl = '/creditor/GetById';
const getCreditorAccountUrl = '/creditoraccount/GetByStatus';
const getCreditorSubAccountUrl = '/creditorsubaccount/GetByIdCreditorAccount';

export default function Edit() {
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const { register, handleSubmit } = useForm();    
    const [listCreditorAccount, setListCreditorAccount] = useState();
    const [creditorSubAccount, setCreditorSubAccount] = useState(null);
    const [creditorInformation, setCreditorInformation] = useState({});
     

    const onSubmit = async (data) => {
        try {
            const response = await axiosPrivate.put(updateCreditorUrl,
                    JSON.stringify({
                        id: id,
                        name: data.name,
                        rfc: data.rfc,
                        creditoraccountid: data.creditorAccountId,
                        creditorsubaccountid: data.creditorSubAccountId,
                        loggedUserName: localStorage.getItem("userName")
                    })
                );
            if (response.data.isSuccessful) {
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage("El acreedor se actualizó exitosamente")]);
                changeComponent(<Detail />, id);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }  
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    };
    const GetCreditorSubAccount = async (paramCreditorAccountId) => {
        try {   
            let resultList = [];
            let itemCreditorAccount = {};
            const response = await axiosPrivate
                .get(getCreditorSubAccountUrl, { params: { idCreditorAccount: paramCreditorAccountId } });
            if (response.data.isSuccessful) {
                if (response.data.data) {
                    response.data.data.map((item) => {
                        itemCreditorAccount = { key: item.id, value: item.id, text: item.description }
                        resultList.push(itemCreditorAccount);
                    })
                }
                return resultList;
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            } 
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    }
    const GetCreditorAccount = async () => {
        try {
            let resultList = [];
            let itemCreditorAccount = {};
            const statusFilterParam = { statusFilter: 1, description: '' };
            const response = await axiosPrivate
                .post(getCreditorAccountUrl, statusFilterParam)
            response.data.data.map((item) => {
                itemCreditorAccount = { key: item.id, value: item.id, text: item.description, selected: false }
                resultList.push(itemCreditorAccount);
            })
            if (response.data.isSuccessful) {
                return resultList;
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            }
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    }
    const GetCreditor = async () => {
        try {
            const response = await axiosPrivate
                .get(getCreditorUrl, { params: { id: id } });
            if (response.data.isSuccessful) {
                return response.data.data;
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(response.data.errorMessage)]);
            } 
        } catch (err) {
            if (!err?.response) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("No Server Response")]);
            } else if (err.response?.status === 400) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(err.response?.data?.errorMessage)]);
            }
        }
    };

    const handleChangeCreditorAccount = (event, data) => {
        const fillDataCreditorSubAccount = async () => {
            try {
                const dataCreditorSubAccount = await GetCreditorSubAccount(data);
                setCreditorSubAccount(dataCreditorSubAccount);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        data == 0 ? setCreditorSubAccount(null) : fillDataCreditorSubAccount();
    };

    useEffect(() => {
        const fillData = async () => {
            try {
                const dataCreditor = await GetCreditor();
                const dataCreditorAccount = await GetCreditorAccount();
                const dataCreditorSubAccount = await GetCreditorSubAccount(dataCreditor.creditorAccountId);
                setCreditorSubAccount(dataCreditorSubAccount);
                setListCreditorAccount(dataCreditorAccount);
                setCreditorInformation(dataCreditor);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        fillData();
    }, [])
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <OmniCard title='Datos generales'>
                {
                    Object.keys(creditorInformation).length > 0 && (
                        <>
                            <OmniTextBox required title='Razón social' register={{ ...register('name') }} focus value={creditorInformation.name} />
                            <OmniTextBox required width='50%' title='RFC' register={{ ...register('rfc') }} value={creditorInformation.rfc} />
                            <OmniSelect required title='Rubro' register={{ ...register('creditorAccountId') }} items={listCreditorAccount} onChange={handleChangeCreditorAccount} value={creditorInformation.creditorAccountId} />
                            <OmniSelect required title='Sub rubro' register={{ ...register('creditorSubAccountId') }} items={creditorSubAccount} value={creditorInformation.creditorSubAccountId} />
                        </>
                    )
                }
            </OmniCard>
        </form>
    )
}