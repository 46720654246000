import { Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useComponentContext, useGeneralStatesContext, } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import ItemsList from "./ItemsList";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import BarMessages from "../../Shared/BarMessages/BarMessages";
import { useFieldChangeWatcherContext } from '../../../Context/Controls/FieldChangeWatcherProvider';
export default function ItemsSelector(props){
    const component = useComponentContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const axiosPrivate = useAxiosPrivate();
    const[originalItems, setOriginalItems]=useState();
    const [rendered, setRendered] = useState(false);
    const [visibleSkeleton, setVisibleSkeleton] = useState(false);
    const 
    {
        setComponent,
        id,
        setId,
        items, 
        setItems, 
        statusFilter, 
        textFilter, 
        setGetURL,
        setSearch
    }= useGeneralStatesContext();
    const getItems = async () =>  {
            var descriptionFilter = textFilter === '*' ? "" : textFilter;
            const statusFilterParam: StatusFilterParam =
            {
                statusFilter: Number(statusFilter),
                description: descriptionFilter
            };
            const response = await axiosPrivate.post
                (
                    props.urlGetItems
                    , statusFilterParam
                    , {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,

                    }
                );
        setItems(response.data.data);
        if(response.data.data?.length>0){
            setId(response.data.data[0].id);
            setComponent(props.detailComponent);
        }
        setVisibleSkeleton(false);
        setSearch(true);
    }
    useEffect(()=>{
        if(rendered==false && props.getInLoad==true){
            setGetURL(props.urlGetItems)
            setRendered(true);
        }
    }, [])
    useEffect(()=>{
        if(visibleSkeleton==false&&id!==undefined){
            SetFocus(id);
        }
    },[visibleSkeleton])
    fieldWatcher.hasPendingChangesRef.current = false;
    return (
        <div id="stkItemSelector" className="item-selector">
            <div id="stkItemList" className="item-selector-list-content">
                <ItemsList 
                    visibleSkeleton={visibleSkeleton}
                    setVisibleSkeleton={setVisibleSkeleton}
                    title={props.title}
                    image={props.image}
                    items={items}
                    originalItems={originalItems}
                    setitems={props.setitems}
                    detailComponent={props.detailComponent}
                    getItems={getItems}
                    searchActiveInRender={ props.searchActiveInRender}
                    omniKey={props.omniKey}
                    omniValue={props.omniValue}
                    omniAcronym={props.omniAcronym}
                    />
            </div>
            <div id="stkItemContainer"
                className="stack-itemsselector-container">
                <BarMessages/>
                {

                    component==undefined
                        ? props.indexComponent === undefined ?
                            <BarButtons rightButtons={props.defaultButtonsRightBar}
                                leftButtons={props.defaultButtonsLeftBar} /> : props.indexComponent
                        :component
                    }
            </div>
        </div>
    )
}

function SetFocus(id){
    console.log("focus: "+id);
    document.getElementById("itm"+id).focus();
}
