const brandUrl = {
    base: '/brand',
    add: '/add',
    get: '/getbyid',
    updateStatus: '/setstatus',
    update: '/update',
    getDefaultValues:'/getDefaultValues',
    GetItemsByBrandId:'/GetItemsByBrandId'
}
var axiosPrivate;

export const SetAxiosPrivate = (axiosPrivateParam) => {
    axiosPrivate = axiosPrivateParam;
}

export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let identificator = data.id ? data.id : 0;
        const response = await axiosPrivate.post(brandUrl.base + brandUrl.add,
            JSON.stringify({
                id: identificator,
                name: data.name,                
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {            
            result.data = response.data.id;           
            result.successMessage = `La marca se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(brandUrl.base + brandUrl.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""  
    }
    try {
        const response = await axiosPrivate.put(brandUrl.base + brandUrl.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            })
        );
        if (response.data.isSuccessful) {           
            let statustext = status ? "activada" : "desactivada";
            result.data = !status;
            result.successMessage = `La marca se ha ${statustext} exitosamente`
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const UpdateAsync = async (id,data) =>
{
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""  
    }
    try {
        const response = await axiosPrivate.put(brandUrl.base + brandUrl.update,
            JSON.stringify({
                id: id,
                name: data.name,
                UpdateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),      
            })
        );
        if (response.data.isSuccessful) {
            result.successMessage = "La marca se actualizó exitosamente";
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }  
    } catch (err) {

        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}
export const GetDefaultValuesAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(brandUrl.base + brandUrl.getDefaultValues);
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
export const GetItemsByBrandId = async (id, page) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.post(brandUrl.base + brandUrl.GetItemsByBrandId,  
            JSON.stringify({
                "id": id,
                "PaginationParams":{
                    "PageNumber":page,
                    "PageSize":100
                }
            })
            //{ params: { Id: id,PaginationParams:null } }
        );
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};
const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}