import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Extents/Edit';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Extents/ExtentStore';

export default function Edit() {
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [extent, setExtent] = useState();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };


    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            setExtent({
                name: result.data.name,
                active: result.data.active
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        let errorMessages = [];
        if (!extent.name) {
            errorMessages.push("La descripción es obligatoria");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            var data = extent;
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = id;
                updateItem({ ...data, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    useEffect(() => {
        getById();
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <OmniTextBox required title="Descripción" focus value={extent?.name} property="name"
                                            edit={setExtent}  maxLength={128} />
                                    </div>

                                </div>
                            </OmniCard>
                        </div>
                    </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

