import { useForm } from "react-hook-form";
import Detail from "./Detail";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Vehicles/Add";
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Vehicles/VehiclesStore'
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { Stack } from "@fluentui/react";
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
export default function Add() {
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const { register, handleSubmit } = useForm();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();

    SetAxiosPrivate(axiosPrivate);

    const add = async (data) => {
        let result = await AddAsync(data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, result.data);
            data.id = result.data;
            addNewItem(data);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    };
    return (
        <form onSubmit={handleSubmit(add)}>
            <BarButtons rightButtons={rightButtons}
                leftButtons={leftButtons} />
            <Stack horizontal>
                <Stack.Item >
                    <OmniCard title='Datos generales'>
                        {
                            useForeignKey &&
                            <OmniTextBox required type='number' title='Clave SAP' register={{
                                ...register('id')
                            }} focus />
                        }
                        <OmniTextBox required title="Alias" register={{ ...register("alias") }} focus={useForeignKey ? false : true} />
                        <OmniTextBox required title="Marca" register={{ ...register("brand") }} />
                        <Stack horizontal >
                            <Stack.Item >
                                <OmniTextBox required width="90%" title="Modelo" register={{ ...register("model") }} />
                            </Stack.Item>
                            <Stack.Item >
                                <OmniTextBox type='number' width="90%" title="Año" register={{ ...register("year") }}/>
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal >
                            <Stack.Item >
                                <OmniTextBox width="90%" title="Color" register={{ ...register("color") }} />
                            </Stack.Item>
                            <Stack.Item >
                                <OmniTextBox width="90%" title="Placa" register={{ ...register("licensePlate") }} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal >
                            <Stack.Item >
                                <OmniTextBox type='number' width="90%" title="Toneladas" register={{ ...register("tons") }} />
                            </Stack.Item>
                            <Stack.Item >
                                <OmniTextBox type='number' width="90%" title="Metros cúbicos" register={{ ...register("cubicMeters") }} />
                            </Stack.Item>
                        </Stack>
                    </OmniCard>
                </Stack.Item>
                <Stack.Item >
                    <OmniCard title='Permisos'>
                        <Stack vertical>
                            <Stack.Item >
                                <OmniCheckBox title="Requiere permiso para transporte privado"
                                    register={{ ...register("requiresPrivateTransportPermit") }} />
                                <OmniTextBox title="Permiso Secretaría de Comunicaciones y Transportes" register={{ ...register("sCTPermission") }} />
                                <OmniTextBox title="Número de permiso" register={{ ...register("sCTNumberPermission") }} />
                            </Stack.Item>
                        </Stack>
                    </OmniCard>
                    <OmniCard title='Aseguradora'>
                        <Stack horizontal >
                            <Stack.Item >
                                <OmniTextBox width="90%" title="Empresa aseguradora" register={{ ...register("insuranceCarrier") }} />
                            </Stack.Item>
                            <Stack.Item >
                                <OmniTextBox width="90%" title="Número de póliza" register={{ ...register("policyNumber") }} />
                            </Stack.Item>
                        </Stack>
                    </OmniCard>
                </Stack.Item>
            </Stack>
        </form>
    )
}
