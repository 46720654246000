import axios from '../../ApiConector/axios';
import useAuth from '../Authentication/useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const currentUser = setAuth.user;

    const refresh = async () => {
        const response = await axios.get('/authentication/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            //console.log('PREV:'+JSON.stringify(prev));
            //console.log(response.data.token);
            return {
                ...prev,
                roleId: response.data.roleId,
                accessToken: response.data.token,
                user: currentUser
            }
        });
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;