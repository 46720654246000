import { Label, } from '@fluentui/react-components';
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css';
export const OmniLabel = (props) => {
    return (

        <div className={'container-controls'}>
            <Label className='label-omni'>{props.text}</Label>
            <div className='container-omni-result'>
                <Label className={props.className}>{props.value}</Label>
            </div>
        </div >
    )
}