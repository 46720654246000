import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Brand/Brand';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const GetByStatus = '/brand/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Brand.svg').default
}
export default function Brand() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de marcas"}
                    image={iconCatalog}
                    urlGetItems={GetByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"name"}
                    omniAcronym={"acronym"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    );
} 