import { useForm } from "react-hook-form";
import { useState } from 'react';
import Detail from "./Detail";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Categories/Add";
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Categories/CategoriesStore'
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
export default function Add() {
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const { register, handleSubmit, getValues } = useForm();
    const changeComponent = useSetStatesContext();
    const [openCallout, setOpenCallout] = useState();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        var data = getValues();
        let result = await AddAsync(data);
        if (result.isSuccessful) {
            changeComponent(<Detail />, result.data);
            data.id = result.data;
            addNewItem({ ...data, updateUser: localStorage.getItem("userName") });
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons rightButtons={rightButtons}
                    leftButtons={leftButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <OmniTextBox required title="Clave" register={{ ...register("key") }} focus maxLength={3} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox required title='Nombre' register={{ ...register('name') }} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Artículos" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }
        </>
    )
}
