const url = {
    base: '/family',
    add: '/add',
    get: '/getbyid',
    updateStatus: '/updatestatus',
    update: '/update',
}

var axiosPrivate;

export const SetAxiosPrivate=(axiosPrivateParam)=>{
    axiosPrivate=axiosPrivateParam;
}


export const GetByIdAsync = async (id) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        const response = await axiosPrivate.get(url.base + url.get, { params: { id: id } });
        if (response.data.isSuccessful) {
            result.data = response.data.data;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateAsync = async (id, data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let familyParam = {
            ...data,
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
        }

        const response = await axiosPrivate.put(url.base + url.update,
            JSON.stringify({
                id: id,
                ...familyParam
            }));
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.successMessage = `La familia se actualizó exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}

export const UpdateStatusAsync = async (id, status) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        const response = await axiosPrivate.put(url.base + url.updateStatus,
            JSON.stringify({
                id: id,
                active: status,
                updateUserId: localStorage.getItem("userId"),
                updateUserName: localStorage.getItem("userName"),
            }));
        let statustext = status ? "activada" : "desactivada";
        if (response.data.isSuccessful) {
            result.isSuccessful = true;
            result.data = !status;
            result.successMessage = `La familia ha sido ${statustext} exitosamente`;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }


    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
}


export const AddAsync = async (data) => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: "",
        successMessage: ""
    }
    try {
        let warehouseResult = {
            ...data,           
            UpdateUserId: localStorage.getItem("userId"),
            updateUserName: localStorage.getItem("userName"),
        }
        const response = await axiosPrivate.post(url.base + url.add,
            JSON.stringify({
                ...warehouseResult
            })
        );
        if (response.data.isSuccessful) {
            result.data = response.data.id;
            result.successMessage = `La familia se guardó exitosamente`;
            result.isSuccessful = true;
        }
        else {
            result.errorMessage = response.data.errorMessage;
        }
    } catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};


const getErrorText=(err)=>{
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}