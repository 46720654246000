import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Client/Detail";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { useState, useEffect } from 'react';
import "./../../../Assets/Styles/Shared/Table/DetailTable.css"
import "./../../../Assets/Styles/Catalogs/Client/Client.css"
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { useGeneralStatesContext, useIdSelectorContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider"
import {
    GetByIdAsync, UpdateStatusAsync, SetAxiosPrivate, generateEmailList, generateObject, GetContactsRol
} from '../../../Functions/Catalogs/Client/ClientStore'
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { ReactComponent as WorldCuvitek } from '../../../Assets/Images/Icons/World.svg';
import { ReactComponent as MexicoFlagCuvitek } from '../../../Assets/Images/Icons/MexicoFlag.svg';
import { CatalogLocation } from './Locations/CatalogLocation';
import { CatalogContacts } from './Contacts/CatalogContacts';
import { CatalogShippingAddresses } from './ShippingAddresses/CatalogShippingAddresses';
import { OtherRoutes } from '../Routes/ClientRoute/OtherRoutes';
import { Detail as DetailChanges } from '../../Shared/UserChanges/Detail';
export default function Detail() {

    const [client, setClient] = useState();
    const id = useIdSelectorContext();
    const fieldWatcher = useFieldChangeWatcherContext();
    const { setListMessageCatalog, updateItemStatus, setLoadingSkeleton } = useGeneralStatesContext();
    const [status, setStatus] = useState('');
    const [locked, setlocked] = useState('');
    const [itemSelected, setItemSelected] = useState();
    const [buttonsClient, setButtonsClient]
        = useState({ leftButtons: leftButtons, rightButtons: rightButtons });
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [isItemDisabled, setIsItemDisabled] = useState(false);
    const [changestatus, setChangestatus] = useState();

    const [contactsRol, setcontactsRol] = useState();
    const [itemsRadioTypeRfc, setItemsRadioTypeRfc] = useState([
        {
            key: "1",
            value: "national",
            text: <MexicoFlagCuvitek />,
            tooltip: "Nacional"
        },
        {
            key: "2",
            value: "foreign",
            text: <WorldCuvitek />,
            tooltip: "Extranjero",
            class: "marginWorld"
        }
    ]);
    const [view, setView] = useState({
        contactsList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        adressList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        },
        locationList: {
            viewAdd: false,
            viewDetail: false,
            viewEdit: false,
        }
    }
    )
    const getById = async () => {
        setIsItemDisabled(true);
        setItemSelected(undefined);
        setView({
            contacts: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            shippingAddresses: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            },
            locationList: {
                viewAdd: false,
                viewDetail: false,
                viewEdit: false,
            }
        })
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            const filterValue = result.data?.rfc === 'XEXX010101000' ? 'foreign' : 'national';

            setItemsRadioTypeRfc(itemsRadioTypeRfc.filter(item => item.value === filterValue));
            setClient({
                id: result.data.id,
                rfc: result.data?.rfc,
                typeRfc: result.data?.rfc === 'XEXX010101000' ? 'foreign' : 'national',
                businessName: result.data?.businessName,
                tradeName: result.data?.tradeName,
                customerType: result.data?.customerType,
                linebusiness: result.data?.linebusiness,
                balance: result.data?.balance,
                observation: result.data?.observation,
                postalCode: result.data?.postalCode,
                contry: result.data?.contry,
                state: result.data?.state,
                municipality: result.data?.municipality,
                colony: result.data?.colony,
                location: result.data?.location,
                street: result.data?.street,
                outdoorNumber: result.data?.outdoorNumber,
                interiorNumber: result.data?.interiorNumber,
                taxRegime: result.data?.taxRegime,
                useCFDI: result.data?.useCFDI,
                paymentMethod: result.data?.paymentMethod,
                distanceConsignmentNoteSupplement: result.data?.distanceConsignmentNoteSupplement,
                breakdownIEPS: result.data?.breakdownIEPS,
                haveCredit: result.data?.haveCredit,
                creditDays: result.data?.creditDays,
                creditLimit: result.data?.creditLimit,
                phone: result.data?.phone,
                fax: result.data?.fax,
                ownerCellphone: result.data?.ownerCellphone,
                buyerCellphone: result.data?.buyerCellphone,
                currentEmails: generateEmailList(result.data?.email),
                processQuotes: result.data?.processQuotes === 4 ? "Factura" : 'Remisión',
                validateDistance: result.data?.validateDistance,
                routes: generateObject(result.data?.listRoutes),
                shippingAddresses: generateObject(result.data?.listShippingAddresses),
                contacts: generateObject(result.data?.listContacts),
                useAddendum: result.data?.useAddendum,
                createDate: result.data?.createDate,
                createUserName: result.data?.createUserName,
                lastUpdateDate: result.data?.lastUpdateDate,
                updateUserName: result.data?.updateUserName,
                createUserFullName: result.data?.createUserFullName,
                updateUserFullName: result.data?.updateUserFullName
            });
            setLoadingSkeleton(false);
            let statusCurrent = (result.data.active == 1 || result.data.active == 3) ? 0 : 1;
            setStatus(statusCurrent);
            let lockedCurrent = (result.data.active == 1 || result.data.active == 0) ? 3 : 1;
            setlocked(lockedCurrent);
            setIsItemDisabled(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const updateStatus = async () => {
        let result = await UpdateStatusAsync(id, status, false);
        if (result.isSuccessful) {
            let statusCurrent = status === 0 ? 1 : 0;
            setStatus(statusCurrent);
            if (status == 1) {
                setlocked(3);
            }
            setChangestatus(true);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const updateStatusLocked = async () => {
        let result = await UpdateStatusAsync(id, locked, true);
        if (result.isSuccessful) {
            let statusCurrent = locked === 3 ? 1 : 3;
            setlocked(statusCurrent);
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        setLoadingSkeleton(true);
        getById();
        fieldWatcher.hasPendingChangesRef.current = false;
        setChangestatus(undefined);
    }, [id])
    function ButtonActive() {
        const updatedButtons = leftButtons.map(button => {
            if (button.key === 'BtnStatus') {
                let statustextbutton = status ? "Activar" : "Inactivar";
                let statusiconbutton = status ? "activeCuvitek" : "inactiveCuvitek";
                let updatedAuthorizationKey = status ? 196 : 4;
                return {
                    ...button, text: statustextbutton, iconName: statusiconbutton, authorizationKey: updatedAuthorizationKey,
                    disabled: (parseFloat(client?.balance.replace("$", "")) > 0 ? true : false),
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} al cliente?`,
                    handler: () => { updateStatus() }
                };
            }
            if (button.key === 'BtnEdit') {
                return {
                    ...button, disabled: (status === 1 ? true : false) || (locked === 1 ? true : false),
                };
            }
            if (button.key === 'BtnBlock') {
                let statustextbutton = locked == 1 ? "Desbloquear" : "Bloquear";
                return {
                    ...button, text: statustextbutton, disabled: (status === 1 ? true : false),
                    alertprompt: `¿Desea ${statustextbutton.toLowerCase()} al cliente?`,
                    handler: () => { updateStatusLocked() }
                };
            }
            return button;
        });
        return updatedButtons;
    }
    const pivotItemButtonProps = {
        disabled: isItemDisabled,
        style: { color: isItemDisabled ? 'var(--secondary)' : 'var(--dark)' }
    };
    const [selectedKey, setSelectedKey] = useState('information');
    const handleLinkClick = (item) => {
        if (item) {
            setSelectedKey(item.props.itemKey);
        }
    };
    useEffect(() => {
        if (client) {
            if (selectedKey && (selectedKey === 'information'
                || selectedKey === 'bill'
                || selectedKey === 'credit'
                || selectedKey === 'route'
                || selectedKey === 'changes')) {
                setButtonsClient(prevEmail => ({
                    ...prevEmail,
                    leftButtons: ButtonActive(),
                    rightButtons: rightButtons
                }));
            }
        }
    }, [selectedKey]);
    useEffect(() => {
        if (client) {
            setButtonsClient(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
        if (changestatus) {
            let data = { ...client };
            updateItemStatus({ ...data, updateUserName: localStorage.getItem("userName") });
        }
    }, [status]);
    useEffect(() => {
        if (client) {
            setButtonsClient(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [locked]);
    useEffect(() => {
        if (client) {
            setButtonsClient(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [changestatus]);
    useEffect(() => {
        if (client) {
            setButtonsClient(prevEmail => ({
                ...prevEmail,
                leftButtons: ButtonActive(),
                rightButtons: rightButtons
            }));
        }
    }, [client]);
    const OnLoadDescripcion = async (url, set) => {
        let result = await url();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.clue ?? item.id,
                value: item.id,
                text: item.description
            }));
            set(resultList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoadDescripcion(GetContactsRol, setcontactsRol);
    }, []);
    function BlockPivots(tipo) {
        if (isItemDisabled) {
            if (selectedKey === tipo) {
                return {
                    disabled: false,
                    style: { color: 'var(--dark)' }
                }
            }
            else {
                return {
                    disabled: true,
                    style: { color: 'var(--secondary)' }
                }
            }
        }
        else {
            return {
                disabled: false,
                style: { color: 'var(--dark)' }
            }
        }
    }
    return (
        <>
            {client &&
                <BarButtons rightButtons={buttonsClient?.rightButtons}
                    leftButtons={buttonsClient?.leftButtons} />
            }
            <Pivot className="pivot-center-client" selectedKey={selectedKey} onLinkClick={handleLinkClick}>
                <PivotItem headerText="Información" itemKey="information" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <OmniLabel text='R.F.C.' value={client?.rfc} />
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            {client?.rfc &&
                                                <OmniRadioGroup name="typeRfc"
                                                    title="Tipo de RFC"
                                                    orientation="horizontal"
                                                    items={itemsRadioTypeRfc}
                                                    default={client?.typeRfc} />}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Razón social' value={client?.businessName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Nombre comercial' value={client?.tradeName} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Tipo de cliente' value={client?.customerType} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniLabel text='Giro del negocio' value={client?.linebusiness} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Particulares'>
                                    <OmniLabel text='Saldo' value={client?.balance} />
                                </OmniCard>
                                <OmniCard title='Observaciones'>
                                    <OmniLabel text='Observaciones' value={client?.observation} />
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Facturación" itemKey="bill" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <OmniLabel text='Código Postal' value={client?.postalCode} />
                                    <Stack horizontal>
                                        <Stack.Item grow>
                                            <OmniLabel text='País' value={client?.contry} />
                                        </Stack.Item>
                                        <Stack.Item grow>
                                            <OmniLabel text='Estado' value={client?.state} />
                                        </Stack.Item>
                                    </Stack>
                                    <OmniLabel text='Municipio' value={client?.municipality} />
                                    <OmniLabel text='Colonia' value={client?.colony} />
                                    <OmniLabel text='Localidad' value={client?.location} />
                                    <OmniLabel text='Calle' value={client?.street} />
                                    <Stack horizontal>
                                        <Stack.Item grow>
                                            <OmniLabel text='Número exterior' value={client?.outdoorNumber} />
                                        </Stack.Item>
                                        <Stack.Item grow>
                                            <OmniLabel text='Número interior' value={client?.interiorNumber} />
                                        </Stack.Item>
                                    </Stack>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Predeterminados'>
                                    <OmniLabel text='Régimen fiscal' value={client?.taxRegime} />
                                    <OmniLabel text='Uso del CFDI predeterminado' value={client?.useCFDI} />
                                    <OmniLabel text='Forma de pago predeterminada' value={client?.paymentMethod} />
                                    <OmniLabel text='Distancia para el complemento de la carta porte' value={client?.distanceConsignmentNoteSupplement} />
                                    <OmniLabel text='Desglosa IEPS' value={client?.breakdownIEPS ? "Si" : "No"} />
                                    <OmniLabel text='Usa adenda' value={client?.useAddendum ? "Si" : "No"} />
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Contacto" headerButtonProps={BlockPivots("contacts")} itemKey="contacts" >
                    <CatalogContacts client={client} setClient={setClient} property="contacts"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClient} setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} clientId={id} statusCatalog={status}
                        contactsRol={contactsRol}
                        lockedCatalog={locked} />

                </PivotItem>
                <PivotItem headerText="Crédito" itemKey="credit" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos crediticios'>
                                    <OmniLabel text='Tiene crédito' value={client?.haveCredit ? "Si" : "No"} />
                                    {(client?.haveCredit) &&
                                        <Stack horizontal>
                                            <Stack.Item grow>
                                                <OmniLabel text='Días de crédito' value={client?.creditDays} />
                                            </Stack.Item>
                                            <Stack.Item grow>
                                                <OmniLabel text='Límite de crédito' value={client?.creditLimit} />
                                            </Stack.Item>
                                        </Stack>
                                    }
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Venta en ruta" itemKey="route" headerButtonProps={pivotItemButtonProps}>
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Preventa'>
                                    <OmniLabel text='Procesar cotizaciones a' value={client?.processQuotes} />
                                </OmniCard>
                                {id &&
                                    <OmniCard title='Rutas de venta'>
                                        <OtherRoutes clientId={id} title='Lista de rutas'/>
                                    </OmniCard>
                                }
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Validar distancia'>
                                    <OmniLabel text='El vendedor se debe estar en el radio del cliente' value={client?.validateDistance ? "Si" : "No"} />
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem headerText="Direcciones de envío" itemKey="shippingAddresses" headerButtonProps={BlockPivots("shippingAddresses")}>
                    <CatalogShippingAddresses client={client} setClient={setClient} property="shippingAddresses"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClient} setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} clientId={id} statusCatalog={status} lockedCatalog={locked}/>
                </PivotItem>
                <PivotItem headerText="Ubicaciones" itemKey="location" headerButtonProps={BlockPivots("location")}>
                    {<CatalogLocation client={client} setClient={setClient} property="shippingAddresses"
                        setIsItemDisabled={setIsItemDisabled}
                        setbuttonsCatalog={setButtonsClient} setItemSelected={setItemSelected}
                        itemSelected={itemSelected}
                        view={view}
                        setView={setView} clientId={id} statusCatalog={status} lockedCatalog={locked}/>}
                </PivotItem>
                <PivotItem headerText="Cambios" itemKey="changes" headerButtonProps={pivotItemButtonProps}>
                    <DetailChanges user={client} />
                </PivotItem>
            </Pivot>
        </>

    )
}