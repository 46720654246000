import { OmniCallout } from '../../../Shared/Callout/OmniCallout';
import BarButtons from '../../../Shared/BarButtons/BarButtons';
import { ContactList } from './ContactList';
import { Contact } from './Contact';
import { ContactDetail } from './ContactDetail';
import { useState, useEffect } from 'react';
import { rightButtons as rightButtonsClientRoute } from "../../../../Assets/Constants/BarButtons/Catalog/Client/Contacts/Contact";
import { leftButtons as leftButtonsClientRouteDetail } from "../../../../Assets/Constants/BarButtons/Catalog/Client/Contacts/Detail";
import {
    leftButtonsEdit as editingButton,
    leftButtonsDetail
} from '../../../../Assets/Constants/BarButtons/Catalog/Client/Editing';
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { UpdateStatusContactsAsync } from '../../../../Functions/Catalogs/Client/ClientStore';
export const CatalogContacts = (props) => {
    const [openCalloutClients, setOpenCalloutClients] = useState();
    const [statusMessage, setStatusMessage] = useState();
    const { setListMessageCatalog } = useGeneralStatesContext();
    const AddContact = () => {
        props.setIsItemDisabled(true);
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: true,
                viewDetail: false,
                viewEdit: false
            }
        }));
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: {}
        }));
    }
    function EditContact() {
        props.setIsItemDisabled(true);
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: true,
                viewDetail: false,
                viewEdit: true
            }
        }));
        props.setbuttonsCatalog(prevEmail => ({
            ...prevEmail,
            leftButtons: editingButton,
            rightButtons: {}
        }));
    }
    function DeleteClient() {
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnStatus"
                    ? { ...button, iconColor: 'var(--danger)' }
                    : button
            )
        }));
        setOpenCalloutClients(true);
    }
    const [buttonsClientRoutesChild, setButtonsClientRoutesChild]
        = useState({
            leftButtonsChild: leftButtonsClientRouteDetail(EditContact, DeleteClient),
            rightButtonsChild: rightButtonsClientRoute(AddContact)
        });
    useEffect(() => {
        if (props?.client && props?.property) {
            if (props?.client[props?.property].length > 0) {
                let showItem = props.itemSelected === undefined ? true : false;
                let itemShow = props.itemSelected !== undefined
                    ? props.itemSelected[props.property] === undefined ? true : false : true;
                if (showItem || itemShow) {
                    props.setItemSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: props?.client[props?.property][0].id
                    }));
                    ChangeActive(props?.client[props?.property][0]);
                    if (props.setView) {
                        props.setView((prevView) => ({
                            ...prevView,
                            [props.property]: {
                                viewAdd: false,
                                viewDetail: true,
                                viewEdit: false
                            }
                        }));
                    }
                }
            }
            else {
                setButtonsClientRoutesChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: {},
                    rightButtonsChild: rightButtonsClientRoute(AddContact).map(button => {
                        if (button.key === "BtnNew") {
                            return { ...button, disabled: ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false)) };
                        }
                        else {
                            return button;
                        }
                    })
                }));
                props.setIsItemDisabled(false);

                props.setbuttonsCatalog(prevEmail => ({
                    ...prevEmail,
                    leftButtons: leftButtonsDetail,
                    rightButtons: {}
                }));
            }
        }
    }, [props.client]);
    useEffect(() => {
        if (props.view[props.property].viewDetail) {
            if (props.itemSelected && props.itemSelected[props.property] !== undefined) {
                let itemCurrent = props.client[props.property].find(x => x.id == [props.itemSelected[props.property]])
                if (itemCurrent) {
                    ChangeActive(itemCurrent);
                }
            }
            else {
                setButtonsClientRoutesChild(prevEmail => ({
                    ...prevEmail,
                    leftButtonsChild: [],
                    rightButtonsChild: rightButtonsClientRoute(AddContact)
                }));
            }
            props.setIsItemDisabled(false);

            props.setbuttonsCatalog(prevEmail => ({
                ...prevEmail,
                leftButtons: leftButtonsDetail,
                rightButtons: {}
            }));
        }
    }, [props.view[props.property]]);
    const AllItemsOk = async () => {
        if (props?.itemSelected && props.itemSelected[props?.property]) {
            const updatedClient = [...props.client[props?.property]];
            let itemCurrent = updatedClient.find(x => x.id == [props.itemSelected[props.property]]);
              let result = await UpdateStatusContactsAsync(itemCurrent.id, !itemCurrent.active);
                if (result.isSuccessful) {
                    updatedClient.find(x => x.id == [props.itemSelected[props.property]]).active = !itemCurrent.active;
                    props.setClient((prevRoute) => {
                        return {
                            ...prevRoute,
                            [props.property]: updatedClient,
                        };
                    });
                    setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                    ChangeActive(itemCurrent);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
                setButtonsClientRoutesChild(prevState => ({
                    ...prevState,
                    leftButtonsChild: prevState.leftButtonsChild.map(button =>
                        button.key === "BtnStatus"
                            ? { ...button, iconColor: 'var(--secondary)' }
                            : button
                    )
                }));
        }
        setOpenCalloutClients(false);
    }
    const AllItemsNo = async () => {
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: prevState.leftButtonsChild.map(button =>
                button.key === "BtnStatus"
                    ? { ...button, iconColor: 'var(--secondary)' }
                    : button
            )
        }));
        setOpenCalloutClients(false);
    }
    useEffect(() => {
        if (props?.itemSelected && props?.property) {
            let itemCurrent = props.client[props.property].find(x => x.id == [props.itemSelected[props.property]])
            if (itemCurrent) {
                ChangeActive(itemCurrent);
            }
        }
    }, [props?.itemSelected && props?.itemSelected[props?.property]]);
    function ChangeActive(itemCurrent) {
        if (itemCurrent?.active) {
            setStatusMessage("¿Desea inactivar este contacto?");
        }
        else {
            setStatusMessage("¿Desea activar este contacto?");
        }
        let statustextbutton = itemCurrent.active ? "Inactivar" : "Activar";
        let statusiconbutton = itemCurrent.active ? "inactiveCuvitek" : "activeCuvitek";
        setButtonsClientRoutesChild(prevState => ({
            ...prevState,
            leftButtonsChild: leftButtonsClientRouteDetail(EditContact, DeleteClient).map(button => {
                if (button.key === "BtnStatus") {
                    return { ...button, text: statustextbutton, iconName: statusiconbutton, disabled: ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false)) };
                } else if (button.key === "BtnEdit") {
                    return { ...button, disabled: (!itemCurrent.active || ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false))) };
                } else {
                    return button;
                }
            }),
            rightButtonsChild: rightButtonsClientRoute(AddContact).map(button => {
                if (button.key === "BtnNew") {
                    return { ...button, disabled: ((props.statusCatalog === 1 ? true : false) || (props.lockedCatalog === 1 ? true : false)) };
                }
                else {
                    return button;
                }
            })
        }));
    }

    return (
        <>
            <BarButtons rightButtons={buttonsClientRoutesChild.rightButtonsChild}
                leftButtons={buttonsClientRoutesChild.leftButtonsChild} />

            <div className='container-fluid container-scroll-three-bars'>
                <div className='row'>
                    <div className='col-md-auto'>
                        <ContactList description={props.client}
                            setSelected={props.setItemSelected}
                            itemSelected={props.itemSelected}
                            setView={props.setView}
                            property={props.property} />
                    </div>
                    <div className='col-md-auto'>
                        {props.view[props.property].viewAdd &&
                            <Contact description={props.client}
                                setDescription={props.setClient}
                                setSelected={props.setItemSelected}
                                itemSelected={props.itemSelected}
                                view={props.view}
                                setView={props.setView}
                                setButtons={setButtonsClientRoutesChild}
                                property={props.property}
                                clientId={props.clientId} 
                            contactsRol={props.contactsRol }                        />
                        }
                        {((props.itemSelected !== undefined && props.itemSelected[props.property] !== undefined) && props.view[props.property].viewDetail) &&
                            <ContactDetail description={props.client}
                                itemSelected={props.itemSelected} property={props.property}  />
                        }
                    </div>
                </div>
            </div>
            {
                <OmniCallout
                    open={openCalloutClients}
                    updateIsOpenAlert={setOpenCalloutClients}
                    leftFunction={AllItemsOk}
                    rightFunction={AllItemsNo}
                    orientation={5}
                    title='Confirmación'
                    content={statusMessage}
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'white'}
                    colorLeft={'var(--danger)'}
                    backgroundColorRight={'white'}
                    colorRight={'var(--secondary)'}
                    borderLeft={'none'}
                    borderRight={'none'}
                    classNameLeft={'callOutButtonLeft'}
                    classNameRight={'callOutButtonRight'}
                    buttonId={"BtnStatus"}
                    barGroupKey={'leftCommandBar'}
                />
            }
        </>

    )
}