import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { useEffect, useState, useRef, useCallback } from 'react';
import { OmniDropdown } from "../../../Shared/Controls/Dropdown/OmniDropdown";
import { rightButtons, leftButtons,rightButtonsSave } from "../../../../Assets/Constants/BarButtons/Catalog/Employees/Handprints/Add";
import {
    AddFingerPrintAsync, GetHands, GetFingers, GetReaders
} from '../../../../Functions/Catalogs/Employees/EmployeeStore'
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Card, CardHeader } from "@fluentui/react-components";
import { CompoundButton, Field, ProgressBar } from "@fluentui/react-components";
import { ReactComponent as HandPrintCuvitek } from '../../../../Assets/Images/Icons/HandPrint.svg';
import { ReactComponent as ReturnWhiteCuvitek } from '../../../../Assets/Images/Icons/ReturnWhite.svg';
import { MessageBar, MessageBarType } from '@fluentui/react';
import axios from '../../../../ApiConector/axios'
import { OmniLabel } from '../../../Shared/Controls/Label/OmniLabel';
const closeMessage = require('../../../../Assets/Images/Icons/Cancel.svg').default;
export const Handprint = (props) => {
    const { setListMessageCatalog } = useGeneralStatesContext();
    const itemSelected = (props.view[props.property].viewEdit ? props.description[props.property].find(x => x.id == [props?.itemSelected[props.property]]) : undefined);
    const initialList = {
        fingerprintTypeId: GetReaders()[0].value,
        fingerprintType: GetReaders()[0].text,
    };
    const [handprint, setHandprint] = useState(itemSelected ?? initialList);
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(ShowReader, Return)
        }));
    }, [])
    function Return() {
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: false,
                viewDetail: true
            }
        }));
    }
    const ShowReader = async () => {
        setIsActive(!isActive)
       
    }
    const [isActive, setIsActive] = useState(false);
    const timerFingerprintDetectedRef = useRef(null);
    const [timerFingerprintDetected, setTimerFingerprintDetected] = useState(0);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const [fingerPrint, setFingerPrint] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [fingerEnrroll, setFingerEnrroll] = useState();
    const [device, setDevice] = useState();
    const [fingerList, setFingerList] = useState();
    const delayTimerFingerprintDetected = 20;

    const [message, setMessage] = useState('Coloque su huella en el lector');
    
    const hidClientServiceAvailable = useRef(false);
    const omniClientServiceAvailable = useRef(false);
    const isHidDeviceConnected = useRef(false);
    const baseUriOmniClientAgent = useRef('');
    //*------- timer  ------
    const getTimeRemainingFingerprintDetected = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return {
            total,
            seconds,
        };
    };

    const stopTimerFingerprintDetected = () => {
        if (timerFingerprintDetectedRef.current) {
            clearInterval(timerFingerprintDetectedRef.current);
        }
    };

    const startTimerFingerprintDetected = (e) => {
        let { total, seconds } = getTimeRemainingFingerprintDetected(e);
        if (total >= 0) {
            setTimerFingerprintDetected(seconds);
        }
        else {
            stopRecognition();
            setIsActive(false);//close panel
            if (timerFingerprintDetectedRef.current) {
                clearInterval(timerFingerprintDetectedRef.current);
            }
            //stop recogrnition intents
            clearInterval(validationSample);
        }
    };

    const clearTimerFingerprintDetected = (e) => {
        setTimerFingerprintDetected(delayTimerFingerprintDetected);

        if (timerFingerprintDetectedRef.current) {
            clearInterval(timerFingerprintDetectedRef.current);
        }
        const id = setInterval(() => {
            startTimerFingerprintDetected(e);
        }, 1000);
        timerFingerprintDetectedRef.current = id;
    };

    const getDeadTimeFingerprintDetected = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + delayTimerFingerprintDetected);
        return deadline;
    };

    //----------- end timer  --------

    const startRecognition = (deviceId) => {
        if (deviceId) {
            window["startFingerprintRecognition"](deviceId);
        }
    }

    const stopRecognition = () => {
        window["stopFingerprintRecognition"]();
    }
    const showPanelFingerprintAuth = async () => {
        var fingerprintReaders = [];
        setShowNotificationMessage(false);

        let isHidClientServiceAvailable = window["hasClientServiceAvailable"]()
        hidClientServiceAvailable.current = isHidClientServiceAvailable;
        if (isHidClientServiceAvailable) {
            await isOmniHidManagerAvailable();
            if (omniClientServiceAvailable.current == true) {
                fingerprintReaders = window["getConnectedDevices"]();
                if (fingerprintReaders.length > 0) {
                    axios.post(baseUriOmniClientAgent.current + "/HIDManager/HasDeviceConnected",
                        JSON.stringify({ "DetectedReaders": fingerprintReaders }),
                        {
                            headers: { 'Content-Type': 'application/json' }
                        }
                    ).then(function (response) {
                        if (response && response.data && response.data.isSuccessful === true) {
                            const deviceId = response.data.deviceId;
                            isHidDeviceConnected.current = true;
                            setDevice(deviceId);
                            startRecognition(deviceId);
                            verifyFingerprintSample();
                            clearTimerFingerprintDetected(getDeadTimeFingerprintDetected());
                        }
                    }).catch(function (error) {
                        if (error && error.response && error.response.data) {
                            let errMsg = error.response.statusText;
                            if (error.response.data.detail) {
                                errMsg = error.response.data.detail;
                            }
                            else if (error.response.data.errorMessage) {
                                errMsg = error.response.data.errorMessage;
                            }
                            showNotification(errMsg);
                        }
                    });
                }
                else {
                    showNotification("No se detectaron lectores biométricos conectados a su computadora.");
                }
            }
        }
        else {
            showNotification("No es posible identificar los dispositivos de huella digital ya que no se ha instalado el software requerido. (Code: HID-RTE)");
        }
    }

    var validationSample;
    function verifyFingerprintSample() {
        validationSample = setInterval(validateSample, 3000);
        function validateSample() {
            let sampleData = window["getSampleData"]();
            if (sampleData) {
                clearInterval(validationSample);
                stopTimerFingerprintDetected();
                setFingerPrint((prevData) => [...prevData, sampleData]);
                stopRecognition();
                setMessage('Retire su huella');
            }
        }
    }
    useEffect(() => {
        if (fingerPrint && fingerPrint.length > 0 && fingerPrint.length < 7 && message === 'Retire su huella') {
            setMessage('Coloque nuevamente su huella');
            startRecognition(device);
            verifyFingerprintSample();
            clearTimerFingerprintDetected(getDeadTimeFingerprintDetected());
        }
        else if (fingerPrint.length > 6 && message === 'Retire su huella') {
            setMessage('Registrando huella');
        }
    }, [fingerPrint]);   
    useEffect(() => {
        if (message === 'Registrando huella') {
            axios.post(baseUriOmniClientAgent.current + "/HIDManager/Enrollment",
                JSON.stringify({ "FingerprintSample": fingerPrint }),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ).then(function (response) {
                if (response && response.data && response.status === 200) {
                    if (response.data.isSuccessful) {
                        setFingerEnrroll(response.data.enrollmentData);
                        setIsActive(false);
                        stopRecognition();
                        clearInterval(validationSample);
                        setFingerPrint([]);
                    }
                    else {
                        showNotification("Error: " + response.data.errorMessage);
                    }
                }
            }).catch(function (error) {
                if (error && error.response && error.response.data) {
                    let errMsg = error.response.statusText;
                    if (error.response.data.detail) {
                        errMsg = error.response.data.detail;
                    }
                    else if (error.response.data.errorMessage) {
                        errMsg = error.response.data.errorMessage;
                    }
                    showNotification(errMsg);
                }
            });
        }
    }, [message]);
    const Save = async () => {
        let errorMessages = [];
        if (!handprint?.handId) {
            errorMessages.push("La mano es obligatoria");
        }
        if (!handprint?.fingerId) {
            errorMessages.push("El dedo es obligatorio");
        }
        if (errorMessages.length > 0) {

            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            const addFingerPrint = {
                ...handprint
            };
            addFingerPrint.userId = props.employeeId;
            addFingerPrint.fingerprintType = addFingerPrint.fingerprintTypeId;
            addFingerPrint.fingerprintSample = fingerEnrroll;
            let result = await AddFingerPrintAsync(addFingerPrint);
            if (result.isSuccessful) {
                addFingerPrint.id = result.data;
                addFingerPrint.fingerprintType = handprint.fingerprintType;
                if (props?.description[props?.property]) {
                    props.setDescription((prevClient) => ({
                        ...prevClient,
                        [props.property]: [...prevClient[props.property], addFingerPrint],
                    }));
                }
                else {
                    props.setDescription((prevClient) => ({
                        ...prevClient,
                        [props.property]: [addFingerPrint],
                    }));
                }
                props.setSelected((prevClient) => ({
                    ...prevClient,
                    [props.property]: result.data
                }));
                props.setView((prevView) => ({
                    ...prevView,
                    [props.property]: {
                        viewAdd: false,
                        viewDetail: true
                    }
                }));
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    async function isOmniHidManagerAvailable() {
        const omniAgentError = "No es posible utilizar los dispositivos de huella digital ya que no se ha instalado el complemento para huellas digitales requerido (Code: OmniAgent). ";
        await axios.get(baseUriOmniClientAgent.current + "/HIDManager/IsAvailable")
            .then(function (response) {
                if (response && response.data && response.status === 200) {
                    if (response.data.isSuccessful) {
                        omniClientServiceAvailable.current = true;
                    }
                    else {
                        showNotification(omniAgentError + " Internal response error.");
                    }
                }
                else {
                    showNotification(omniAgentError);
                }
            }).catch(function (error) {
                if (error) {
                    showNotification(omniAgentError);
                }
            });
    }

    useEffect(() => {
        window["initFingerprintDevice"]();

        async function getBaseUriAgent() {
            const omniAgentError = "No es posible obtener la configuración del Agente de Omni. ";
            await axios.get("/hidauthentication/GetBaseUriOmniAgent")
                .then(function (response) {
                    if (response && response.data && response.status === 200) {
                        if (response.data.isSuccessful) {
                            baseUriOmniClientAgent.current = response.data.data;
                        }
                        else {
                            showNotification(omniAgentError || " Internal response error.");
                        }
                    }
                    else {
                        showNotification(omniAgentError);
                    }
                }).catch(function (error) {
                    if (error) {
                        showNotification(omniAgentError);
                    }
                });
        }
        getBaseUriAgent();

        return () => {
            stopRecognition();
        }
    }, []);

    function showNotification(message) {
        if (message) {
            setNotificationMessage(message);
            setShowNotificationMessage(true);
        }
    }
    useEffect(() => {
        if (fingerEnrroll) {
            props.setButtons(prevEmail => ({
                ...prevEmail,
                leftButtonsChild: leftButtons,
                rightButtonsChild: rightButtonsSave(Save, Return)
            }));
        }
    }, [fingerEnrroll])
    useEffect(() => {
            props.setButtons(prevEmail => ({
                ...prevEmail,
                leftButtonsChild: leftButtons,
                rightButtonsChild: fingerEnrroll ? rightButtonsSave(Save, Return) : rightButtons(ShowReader, Return)
            }));
    }, [handprint])
    const onRenderFooterContent = useCallback(
        () => (
            <div>
                <CompoundButton id="btnCancelBiometricAuth" className="tile-button"
                    icon={<ReturnWhiteCuvitek />}
                    iconPosition="after"
                    shape="square" size="small"
                    onClick={() => {
                        setIsActive(false);
                        stopRecognition();
                        clearInterval(validationSample);
                        setFingerPrint([]);
                    }}
                >Cancelar</CompoundButton>
            </div>
        ),
        [isActive],
    );
    const onChangeItem = async (id, text) => {
        const resultList = props?.description[props?.property] && props?.description[props?.property].length > 0
            ? GetFingers().filter((item) => !props?.description[props?.property].some((route) => route.finger == item.text && route.hand == text && route.fingerprintType == "HID")) : GetFingers();
        setFingerList(resultList);
    }
    return (
         <>
        <OmniCard title='Datos de la huella'>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <OmniDropdown title='Mano' items={GetHands()}
                        property="handId" name="hand" required
                        edit={setHandprint}
                        value={handprint?.hand}
                            selected={handprint?.handId} onChangeItem={onChangeItem}/>
                        <OmniDropdown title='Dedo' items={fingerList}
                        property="fingerId" name="finger" required
                        edit={setHandprint}
                        value={handprint?.finger}
                        selected={handprint?.fingerId} />
                    <OmniDropdown title='Lector' items={GetReaders()}
                            property="fingerprintTypeId" name="FingerprintType" required
                        edit={setHandprint}
                            value={handprint?.fingerprintType}
                            selected={handprint?.fingerprintTypeId} />
                        {(fingerEnrroll && fingerEnrroll.length > 4) &&
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniLabel text='Lectura de la huella' value={"Correcta"} className="label-success" />
                                </div>
                            </div>
                        }
                </div>
            </div>
        </OmniCard>
            <Panel isOpen={isActive}
                isFooterAtBottom={true}
                className="fingerprint-login-panel"
                onOpen={showPanelFingerprintAuth}
                hasCloseButton={false}
                headerText="Lectura de huella digital"
                type={PanelType.custom}
                customWidth={'466px'}
                overlayProps={{ className: 'fingerprint-login-panel-overlay' }}
                onRenderFooterContent={onRenderFooterContent}
            >

                <div>
                    {(showNotificationMessage) && (
                        <MessageBar
                            className="login-fingerprint-notification"
                            dismissButtonAriaLabel="Close"
                            messageBarType={MessageBarType.warning}
                            dismissIconProps={{
                                imageProps: {
                                    src: closeMessage
                                }
                            }}
                        >
                            <b>{notificationMessage}</b>
                        </MessageBar>
                    )}
                    {hidClientServiceAvailable.current && omniClientServiceAvailable.current && isHidDeviceConnected.current && (
                        <div>
                            <div className="login-fingerprint-message">Cuvitek Omni requiere registrar su identidad.</div>

                            <Card className="card-tile" >
                                <CardHeader className="card-tile-header"
                                    image={<HandPrintCuvitek />}
                                    header={ message}
                                />

                                <p >
                                    <Field
                                        validationMessage={
                                            <div className="card-tile-progress-bar-description" >Esperando identificación
                                                <div className="countdown">{timerFingerprintDetected} segundos</div>
                                            </div>
                                        }
                                        validationState="none"
                                    >
                                        <ProgressBar max={20} value={timerFingerprintDetected} thickness="large" shape="square" className="card-tile-progress-bar card-tile-progress-bar-info" />

                                    </Field>
                                </p>
                            </Card>

                        </div>
                    )}

                </div>
            </Panel>
        </>
    )
}