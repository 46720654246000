const getByIdUrl = '/accountingtax/getbyid';
const updateStatusUrl = '/accountingtax/updatestatus';
const updateUrl = '/accountingtax/update';
const addUrl = '/accountingtax/add';

var axiosPrivate;

export const SetAxiosPrivate=(axiosPrivateParam)=>{
    axiosPrivate=axiosPrivateParam;
}


export const GetByIdAsync=async(id)=>{
    var result={
        isSuccessful:false,
        data:null,
        errorMessage:""   
    }
    try {
        const response = await axiosPrivate.get(getByIdUrl, {
            params: {
                id: id
            }
        },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        result.data=response.data.data;
        result.isSuccessful=true;
    } catch (err) {
        if (!err?.response) {
            result.errorMessage="No server response";
        } else if (err.response?.status === 400) {
            result.errorMessage=err.response?.data?.errorMessage
        }
    }
    return result;
}

export const UpdateStatusAsync = async (id, status) => {
    var result={
        isSuccessful:false,
        data:null,
        errorMessage:"",
        successMessage:""   
    }
    try {
        const response = await axiosPrivate.put(updateStatusUrl,
            JSON.stringify({
                id: id,
                active: status,
                updatingUser: localStorage.getItem("userName")
            }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        let statustext = status ? "activo" : "desactivo";
        if(response.data.isSuccessful){
            result.isSuccessful=true;
            result.data=!status;
            result.successMessage=`El impuesto se ${statustext} exitosamente`;
        }
        else{
            result.errorMessage=response.data.errorMessage;
        }

        
    } catch (err) {
        let errorMessage=getErrorText(err);
        result.errorMessage=errorMessage;
    }
    return result;
}

export const UpdateAsync = async (data) => {
    var result={
        isSuccessful:false,
        data:null,
        errorMessage:"",
        successMessage:""   
    }
    try {
        const response = await axiosPrivate.put(updateUrl,
            JSON.stringify({
                "id": data.id,
                "description": data.description,
                "acronym": data.acronym,
                "taxRate": data.taxRate,
                "updatingUser": localStorage.getItem("userName")
            }));
            if(response.data.isSuccessful){
                result.isSuccessful=true;
                result.successMessage=`El impuesto se actualizó exitosamente`;
            }
            else{
                result.errorMessage=response.data.errorMessage;
            }
    } catch (err) {
        
        let errorMessage=getErrorText(err);
        result.errorMessage=errorMessage;
    }
    return result;
};

export const AddAsync = async (data) => {
    var result={
        isSuccessful:false,
        data:null,
        errorMessage:"",
        successMessage:""   
    }
    try {
        const response = await axiosPrivate.post(addUrl,
            JSON.stringify({
                description: data.description,
                acronym: data.acronym,
                taxRate: data.taxRate,
                creationUser: localStorage.getItem("userName")
            }));
            result.data=response.data.id;
            if(response.data.isSuccessful){
                result.isSuccessful=true;
                result.successMessage=`El impuesto se guardó exitosamente`;
            }
            else{
                result.errorMessage=response.data.errorMessage;
            }
    } catch (err) {
        let errorMessage=getErrorText(err);
        result.errorMessage=errorMessage;
    }
    return result;
};


const getErrorText=(err)=>{
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}