import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Employees/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AddAsync, SetAxiosPrivate, GetRole, Age, DischargeDate, ExistUserNameAsync } from '../../../Functions/Catalogs/Employees/EmployeeStore';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { OmniDatePicker } from '../../Shared/Controls/DatePicker/OmniDatePicker';
import { OmniLabel } from '../../Shared/Controls/Label/OmniLabel';
import { Add as AddEmail } from '../../Shared/EmailList/Add';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniDropdown } from '../../Shared/Controls/Dropdown/OmniDropdown';
import { CheckmarkCircle20Regular, DismissCircle20Regular } from '@fluentui/react-icons';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
const iconStyleProps = {
    primaryFill: 'var(--primary)',
};
const iconStylePropsDanger = {
    primaryFill: 'var(--danger)',
};
const passwordStyles = {
        fontSize : '12px',
};
export default function Add() {
    const itemsRadioGender = [
        {
            key: "0",
            value: "0",
            text: "Hombre",
            item:false
        },
        {
            key: "1",
            value: "1",
            text: "Mujer",
            item: true
        }
    ];
    const itemsRadioAcessType = [
        {
            key: "0",
            value: "0",
            text: "Manual y biométrico",
            item: false
        },
        {
            key: "1",
            value: "1",
            text: "Solo biométrico",
            item: true
        }
    ];
    const initial = {
        gender: "0",
        listEmail: [],
        useOnlyFingerprint: "0",
        dischargeDate: new Date('2000-01-01T03:24:00'),
        birthdate: new Date('2000-01-01T03:24:00'),

    };
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const [user, setUser] = useState(initial);
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [security, setSecurity] = useState({
        length: false,
        uppercase:false,
        number: false,
        specials:false
    });
    const [progressBarPassword, setProgressBarPassword] = useState(<div className="progress-bar bg-danger" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>);
    const [role, setRole] = useState();
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        surname: undefined,
        userName: undefined,
        passwordRepeat: undefined
    });
    const submit = async () => {
        setOpenCallout(true);
    };

    const AddConfirm = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!user.name) {
            errorMessages.push("El nombre es obligatorio");
        }

        if (!user.surname) {
            errorMessages.push("El apellido paterno es obligatorio");
        }
        if (!user.userName) {
            errorMessages.push("El nombre de usuario es obligatorio");
        }
        if (user.roleId == undefined || user.roleId == null) {
            errorMessages.push("El rol es obligatorio");
        }
        if (user.password) {
            if (user.password !== user.passwordRepeat) {
                errorMessages.push("Las contraseñas deben ser iguales para poder continuar");
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    passwordRepeat: 'Las contraseñas deben ser iguales para poder continuar'
                }));
            }
        }
        if (errorMessages.length > 0) {

            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            let data = user;
            data.gender = itemsRadioGender.find(item => item.value === data.gender).item;
            data.useOnlyFingerprint = itemsRadioAcessType.find(item => item.value === data.useOnlyFingerprint).item;
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                user.id = result.data;
                data.fullName = user.name + " " + user.surname + " " + user.secondSurname;
                addNewItem({ ...user, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    useEffect(() => {
        if (user?.birthdate) {
            setUser(us => ({
                ...us,
                age: Age(user?.birthdate)
            }));
        }
    }, [user?.birthdate])
    useEffect(() => {
        if (user?.dischargeDate) {
            setUser(us => ({
                ...us,
                dischargeDateLabel: DischargeDate(user?.dischargeDate)
            }));
        }
    }, [user?.dischargeDate])
    const onChangePassword = (event) => {
        const text = event.target.value;
        let countPassword = 0;
        if (text.length >= 8) {
            setSecurity(prev => ({
                ...prev,
                length: true
            }));
            countPassword++;
        }
        else {
            setSecurity(prev => ({
                ...prev,
                length: false
            }));
        }
        if (text.match(/\d/)) {
            setSecurity(prev => ({
                ...prev,
                number: true
            }));
            countPassword++;
        } else {
            setSecurity(prev => ({
                ...prev,
                number: false
            }));
        }
        if (text.match(/[@#!$%&/()=?¿¡*+-.,_]/g)) {
            setSecurity(prev => ({
                ...prev,
                specials: true
            }));
            countPassword++;
        } else {
            setSecurity(prev => ({
                ...prev,
                specials: false
            }));
        }
        if (text.match(/[A-Z]/)) {
            setSecurity(prev => ({
                ...prev,
                uppercase: true
            }));
            countPassword++;
        } else {
            setSecurity(prev => ({
                ...prev,
                uppercase: false
            }));
        }
        switch (countPassword) {
            case 1:
                setProgressBarPassword(<div className="progress-bar bg-danger" role="progressbar" style={{ width: "33%" }} aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>);
                break;
            case 2:
                setProgressBarPassword(<div className="progress-bar bg-warning" role="progressbar" style={{ width: "66%" }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>);
                break;
            case 3:
                setProgressBarPassword(<div className="progress-bar bg-warning" role="progressbar" style={{ width: "66%" }} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>);
                break;
            case 4:
                setProgressBarPassword(<div className="progress-bar bg-success" role="progressbar" style={{ width: "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>);
                break;
            default:
                setProgressBarPassword(<div className="progress-bar bg-danger" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>);
                break;
        }
    };
    const onBlurName = (event) => {
        ValidText(event, "name", "nombre");
    };
    const onBlurSurName = (event) => {
        ValidText(event, "surname", "apellido paterno");
    };
    const onBlurUserName = async (event) => {
        ValidText(event, "userName", "nombre de usuario");
        if (event.target.value.length < 3) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                userName: 'la longitud mínima deben ser 3 caracteres'
            }));
            event?.target?.focus();
        }
        else {
            let result = await ExistUserNameAsync(event.target.value)
            if (result.isSuccessful) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    userName: undefined
                }));
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    userName: result.errorMessage
                }));
                event?.target?.focus();
            }
        }
    };
    const onBlurPasswordRepeat = (event) => {
        let text = event.target.value;
        if (text === user.password) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                passwordRepeat: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                passwordRepeat: 'Las contraseñas deben ser iguales para poder continuar'
            }));
            event?.target?.focus();
        }
    };
    function ValidText(event, property,fild) {
        let text = event.target.value;
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: `El campo ${fild} es obligatorio`
            }));
            event?.target?.focus();
        }
    }
    const OnGetRole = async () => {
        let result = await GetRole();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.roleName
            }));
            setRole(resultList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnGetRole();
    }, []);
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons rightButtons={rightButtons}
                    leftButtons={leftButtons} />
                <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                >
                    <PivotItem headerText="Información" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox required title='Nombre'
                                                    focus property="name"
                                                    edit={setUser} maxLength={40} validationMessage={showErrors.name}
                                                    value={user?.name} blur={onBlurName}/>
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox required title='Apellido paterno'
                                                     property="surname"
                                                    edit={setUser} maxLength={50} validationMessage={showErrors.surname}
                                                    value={user?.surname} blur={onBlurSurName} />
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Apellido materno'
                                                     property="secondSurname"
                                                    edit={setUser} maxLength={50} 
                                                    value={user?.secondSurname} />
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniRadioGroup name="gender"
                                                    title="Sexo"
                                                    orientation="horizontal"
                                                    items={itemsRadioGender}
                                                    default={user.gender}
                                                    propertyId="gender"
                                                    edit={setUser} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniDatePicker title='Fecha de nacimiento'
                                                    property="birthdate"
                                                    edit={setUser}
                                                    value={user?.birthdate} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniLabel value={user?.age} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Datos de contacto'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <AddEmail description={user}
                                                    setDescription={setUser}
                                                    titleColum="Lista de correos" property="listEmail"
                                                    titleTextBox="Correo electrónico" />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox title="Teléfono celular"
                                                    value={user?.mobilePhone}
                                                    property="mobilePhone" edit={setUser} customMask={"999-999-9999"} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Acceso" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Acceso'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniRadioGroup name="useOnlyFingerprint"
                                                    title="Tipo de acceso"
                                                    orientation="horizontal"
                                                    items={itemsRadioAcessType}
                                                    default={user.useOnlyFingerprint}
                                                    propertyId="useOnlyFingerprint"
                                                    edit={setUser} />
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox required title='Nombre del usuario'
                                                    focus property="userName"
                                                    edit={setUser} maxLength={16} validationMessage={showErrors.userName}
                                                    value={user?.userName} blur={onBlurUserName} />
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Contraseña'
                                                    property="password" type="password"
                                                    edit={setUser} maxLength={16} validationMessage={showErrors.password}
                                                    onChange={onChangePassword} notuppercase={true} value={user?.password}                                             />
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <span>La contraseña debe cumplir con los siguientes requisitos: </span>
                                                    <ul>
                                                        <li>
                                                            {security.length === true ? < CheckmarkCircle20Regular {...iconStyleProps} />
                                                                : <DismissCircle20Regular {...iconStylePropsDanger} />
                                                            }
                                                            <span style={passwordStyles}>  Mínimo 8 caracteres</span>
                                                        </li>
                                                        <li>
                                                            {security.uppercase === true ? < CheckmarkCircle20Regular {...iconStyleProps} />
                                                                : <DismissCircle20Regular {...iconStylePropsDanger} />
                                                            }
                                                            <span style={passwordStyles}>  Mayúsculas</span>
                                                        </li>
                                                        <li>
                                                            {security.number === true ? < CheckmarkCircle20Regular {...iconStyleProps} />
                                                                : <DismissCircle20Regular {...iconStylePropsDanger} />
                                                            }
                                                            <span style={passwordStyles}>  Numeros</span>
                                                        </li>
                                                        <li>
                                                            {security.specials === true ? < CheckmarkCircle20Regular {...iconStyleProps} />
                                                                : <DismissCircle20Regular {...iconStylePropsDanger} />
                                                            }
                                                            <span style={passwordStyles}>  Caraceteres especiales</span>
                                                        </li>
                                                    </ul>
                                                    <div className="progress">
                                                        {progressBarPassword}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox  title='Repetir contraseña'
                                                    property="passwordRepeat" type="password"
                                                    edit={setUser} maxLength={16}
                                                    validationMessage={showErrors.passwordRepeat} value={user?.passwordRepeat} notuppercase={true} blur={onBlurPasswordRepeat}/>
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Asistencia'>
                                        <div className='col-lg-12 col-md-12'>
                                        <OmniCheckBox title="Permite registro de asistencia manual"
                                            propertyId="allowAttendanceWithUserPassword"
                                            edit={setUser}
                                            propertyChecked={user.allowAttendanceWithUserPassword} />
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Puesto" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12'>
                                    <OmniCard title='Puesto'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre del puesto'
                                                    property="position" focus
                                                    edit={setUser} maxLength={100} value={user?.position}/>
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniDropdown title='Rol dentro del sistema' items={role}
                                                    property="roleId" name="role" required
                                                    edit={setUser}
                                                    value={user?.role}
                                                    selected={user?.roleId} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniDatePicker title='Fecha de alta'
                                                    property="dischargeDate"
                                                    edit={setUser}
                                                    value={user?.dischargeDate} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniLabel value={user?.dischargeDateLabel} />
                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title="Nómina" maxLength={100} value={user?.payrollId} property="payrollId"
                                                    edit={setUser} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Huellas" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}
